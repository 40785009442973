function CreatePdf(result,asresult){
    const title = '■■　受注登録指示書　■■';
    const customerName = '得意先名';
    const viaName = '経由先名';
    const postMark = '〒';
    const shippingWareHouse = '出荷倉庫';
    const deliveryCategory = '配送区分';
    const fareCategory = '運賃区分';
    const deliveryName = '納品先名';
    const deliveryAddress = '納品先住所';
    const deliveryPhoneNumber = '納品先電話番号';
    const customerOrderNumber = '客先注文No.'
    const deliveryDate = 'お届け指定日';
    const timeDesignation = '時間指定';
    const shippingCompanyDesignation = '運送会社指定';
    const propertyName = '物件名';
    const invoiceContent = '送り状付記内容';
    const invoiceFaxRequest = '送り状FAX依頼';
    const faxExistence = 'FAX有無';
    const mapExistence = '地図有無';
    const lineNumber = 'No.';
    const productCode = '製品コード';
    const productName = '品名';
    const quantity = '数量';
    const unitPrice = '単価';
    const customerProductCode = '客先製品コード';
    const shippingScheduleDate = '出荷予定日';
    const orderNumber = '受注番号';
    const remark = '備考';
    const constructionSiteInfo = '備考2';
    var pdfData = [];
    var prdcts = [];
    var tmpData = [];
    var prdctsData = [];
    for(var d = 0; d < result.length; d++){
        for(var j = 0;j < asresult.length; j++){
            if(asresult[j].REMARKS2 != null){
            asresult[j].REMARKS2 = asresult[j].REMARKS2.replace(/　/g,"\n");// eslint-disable-line
            }
            if(asresult[j].SHPPNG_CMPNY == null){
                asresult[j].SHPPNG_CMPNY = "";
            }
            if(asresult[j].CMPNY == null){
                asresult[j].CMPNY = "指定なし";
            }
            if(asresult[j].INVICE_FAX_RQEST == null){
                asresult[j].INVICE_FAX_RQEST = "";
            }
            if(asresult[j].INVICE_FAX_PRSNC == null){
                asresult[j].INVICE_FAX_PRSNC = "";
            }
            if(asresult[j].MAP_PRSNC == null){
                asresult[j].MAP_PRSNC = "";
            }
            if(asresult[j].FAX_RQEST == null){
                asresult[j].FAX_RQEST = "無";
            }
            if(asresult[j].FAX_PRSNC == null){
                asresult[j].FAX_PRSNC = "無";
            }
            if(asresult[j].PRSNC == null){
                asresult[j].PRSNC = "無";
            }
            if(result[d].MNGMNT_NUM === asresult[j].MNGMNT_NUM){
                tmpData.push([
                    { text: asresult[j].ITM_NO/10, border: [false, false, false, false]},
                    { text: asresult[j].PRDCT_CD, border: [false, false, false, false]},
                    { text: asresult[j].PRDCT_NM, border: [false, false, false, false]},
                    { text: asresult[j].QTY, border: [false, false, false, false]},
                    { text: asresult[j].UNIT_PRC, border: [false, false, false, false]},
                    { text: asresult[j].CSTMR_PRDCT_CD, border: [false, false, false, false]},
                    { text: asresult[j].SHPPNG_SCDLE_DT, border: [false, false, false, false]},
                    { text: asresult[j].ORDR_NUM, border: [false, false, false, false]},
                ]);
            }else {
                continue;
            }
        }
        prdctsData.push(...[
            tmpData,
        ]);
        tmpData = [];
    }
    pdfMake.fonts = {// eslint-disable-line
        ipam: {
            normal: 'migmix-2p-regular.ttf',
            bold: 'migmix-2p-bold.ttf',
            italics: 'ipaexm.ttf',
            bolditalics: 'ipaexm.ttf'
        }
    };
    for(var i = 0; i < result.length; i++){
        var pageBreaks ='after';// eslint-disable-line
        if(result.length-1 == i){
            pageBreaks ='null';
        }
        const docDefinition = {         
            pageOrientation: 'landscape',
            content: [
                {
                    margin:[0,0,0,0],
                    fontSize:10,
                    alignment: 'left',
                    layout: 'noBorders',
                    table: {
                        headerRows: 1,
                        widths: ['*'],
                        body: [
                            [
                                [
                                    {
                                        style: 'tableExample',
                                        columns: [
                                            {
                                                text: title,
                                                fontSize: 20,
                                                width: 330,
                                                margin: [15,0,0,0],
                                            },
                                            {
                                                table: {
                                                    widths: [70, 'auto'],
                                                    body: [
                                                        [
                                                            {
                                                                border: [false, false, false, true],
                                                                text: customerName,
                                                                bold: true,
                                                            },
                                                            {
                                                                border: [false, false, false, true],
                                                                text: result[i].CSTMR_NM,
                                                                bold: true,
                                                                margin: [0, 0, 50, 0],
                                                            },
                                                        ],
                                                        [
                                                            {
                                                                border: [false, false, false, false],
                                                                text: viaName,
                                                                margin: [0, 3, 0, 0],
                                                                fontSize: 10,
                                                                bold: true,
                                                            },
                                                            {
                                                                border: [false, false, false, false],
                                                                text: result[i].VIA_NM,
                                                                margin: [0, 3, 0, 0],
                                                                fontSize: 10,
                                                            },
                                                        ],                    
                                                    ]
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        table: {
                                            widths: ['auto','auto'],
                                            body: [
                                                [
                                                    [
                                                        {
                                                            columns: [
                                                                {
                                                                    width: 60,
                                                                    text: " ",
                                                                    fontSize: 10,
                                                                    bold: true,
                                                                    margin: [4,0,0,5],
                                                                },
                                                            ],
                                                        },
                                                        {
                                                            columns: [
                                                                {
                                                                    width: 60,
                                                                    text: deliveryName,
                                                                    fontSize: 10,
                                                                    bold: true,
                                                                    margin: [4,0,0,5],
                                                                },
                                                                {
                                                                    width: 'auto',
                                                                    text: result[i].DLVRY_DSTNTON_NM_1 + ' ' + result[i].DLVRY_DSTNTON_NM_2,
                                                                    fontSize: 10,
                                                                    margin: [30,0,0,5],
                                                                },
                                                            ],
                                                        },
                                                        {
                                                            table: {
                                                                widths: [68,2,50,180,100,100],
                                                                body: [
                                                                    [
                                                                        {
                                                                            border: [false, false, false, false],
                                                                            text: deliveryAddress,
                                                                            fontSize: 10,
                                                                            bold: true,
                                                                        },
                                                                        {
                                                                            border: [false, false, false, false],
                                                                            text: postMark,
                                                                            fontSize: 10,
                                                                        },
                                                                        {
                                                                            border: [false, false, false, false],
                                                                            text: result[i].PST_CD,
                                                                            fontSize: 10,
                                                                        },
                                                                        {
                                                                            border: [false, false, false, false],
                                                                            text: result[0].DLVRY_ADDRSS_1 + ' ' + result[i].DLVRY_ADDRSS_2,
                                                                            fontSize: 10,
                                                                            margin: [20,0,0,0],
                                                                        },
                                                                        {
                                                                            border: [false, false, false, false],
                                                                            text: deliveryPhoneNumber,
                                                                            fontSize: 10,
                                                                            bold: true,
                                                                            margin: [20,0,0,0],
                                                                        },
                                                                        {
                                                                            border: [false, false, false, false],
                                                                            text: result[i].PHNE_CD,
                                                                            fontSize: 10,
                                                                            margin: [20,0,0,0],
                                                                        }, 
                                                                    ],
                                                                ]
                                                            },
                                                            layout: {
                                                                hLineWidth: function(i, node) {
                                                                    return (i === 0 || i === node.table.body.length) ? 0.1 : 0.1;
                                                                },
                                                                vLineWidth: function(i, node) {
                                                                    return (i === 0 || i === node.table.widths.length) ? 0.1 : 0.1;
                                                                }
                                                            },
                                                        },
                                                    ],
                                                    [
                                                        {
                                                            alignment: 'left',
                                                            margin: [40,0,0,0],
                                                            table: {
                                                                widths: ['auto',80],
                                                                body: [
                                                                    [
                                                                        {
                                                                            border: [true, true, false, true],
                                                                            text: shippingWareHouse,
                                                                            fontSize: 10,
                                                                            bold: true,
                                                                        },
                                                                        {
                                                                            border: [false, true, true, true],
                                                                            text: result[i].SHPPNG_WREHS + ' : ' + result[i].WREHS,
                                                                            fontSize: 10,
                                                                            margin: [20,0,0,0],
                                                                        },
                                                                    ],
                                                                    [
                                                                        {
                                                                            border: [true, false, false, true],
                                                                            text: deliveryCategory,
                                                                            fontSize: 10,
                                                                            bold: true,
                                                                        },
                                                                        {
                                                                            border: [false, false, true, true],
                                                                            text: result[i].SHPPNG_KBN + ' : ' + result[i].SH_KBN,
                                                                            fontSize: 10,
                                                                            margin: [20,0,0,0],
                                                                        },
                                                                    ],
                                                                    [
                                                                        {
                                                                            border: [true, false, false, true],
                                                                            text: fareCategory,
                                                                            fontSize: 10,
                                                                            bold: true,
                                                                        },
                                                                        {
                                                                            border: [false, false, true, true],
                                                                            text: result[i].SHPPNG_EXPNS_KBN + ' : ' + result[i].EXPNS_KBN,
                                                                            fontSize: 10,
                                                                            margin: [20,0,0,0],
                                                                        },
                                                                    ],
                                                                ],
                                                            },
                                                            layout: {
                                                                hLineStyle: function (i, node) {
                                                                    if (i === 0 || i === node.table.body.length) {
                                                                        return null;
                                                                    }
                                                                    return {dash: {length: 1, space: 1}};
                                                                },
                                                                hLineWidth: function(i, node) {
                                                                    return (i === 0 || i === node.table.body.length) ? 0.1 : 0.1;
                                                                },
                                                                vLineWidth: function(i, node) {
                                                                    return (i === 0 || i === node.table.widths.length) ? 0.1 : 0.1;
                                                                },
                                                            },
                                                        },
                                                    ],
                                                ],
                                            ]
                                        },
                                    layout: 'noBorders',
                                },

                                {
                                    margin:[0,0,0,0],
                                    table: {
                                        widths: [25,340,30,340],
                                        body: [
                                            [
                                                {
                                                    border: [false, true, false, true],
                                                    text: remark,
                                                    fontSize: 10,
                                                    bold: true,
                                                },
                                                {
                                                    border: [false, true, false, true],
                                                    text: result[i].REMARKS,
                                                    fontSize: 8,
                                                },
                                                {
                                                    border: [false, true, false, true],
                                                    text: constructionSiteInfo,
                                                    fontSize: 10,
                                                    bold: true,
                                                },
                                                {
                                                    border: [false, true, false, true],
                                                    text: result[i].REMARKS2,
                                                    fontSize: 8,
                                                },
                                            ],
                                        ]
                                    },
                                    layout: {
                                        hLineWidth: function(i, node) {
                                            return (i === 0 || i === node.table.body.length) ? 0.1 : 0.1;
                                        },
                                        vLineWidth: function(i, node) {
                                            return (i === 0 || i === node.table.widths.length) ? 0.1 : 0.1;
                                        }
                                    },      
                                },
                                {
                                    margin:[0,10,0,0],
                                    table: {
                                        widths: [77,80,60,80,50,20,80,100],
                                        body: [
                                            [
                                                {
                                                    border: [false, false, false, true],
                                                    text: customerOrderNumber,
                                                    fontSize: 10,
                                                    bold: true,
                                                },
                                                {
                                                    border: [false, false, false, true],
                                                    text: result[i].CSTMR_ORDR_NO,
                                                    fontSize: 10,
                                                },
                                                {
                                                    border: [false, false, false, true],
                                                    text: deliveryDate,
                                                    fontSize: 10,
                                                    bold: true,
                                                },
                                                {
                                                    border: [false, false, false, true],
                                                    text: result[i].DT_ARVL_DAGNTN,
                                                    fontSize: 10,
                                                    margin: [0,0,0,0],
                                                },
                                                {
                                                    border: [false, false, false, true],
                                                    text: timeDesignation,
                                                    fontSize: 10,
                                                    bold: true,
                                                    margin: [10,0,0,0],
                                                },
                                                {
                                                    border: [false, false, false, true],
                                                    text: result[i].TM_ARVL_DAGNTN,
                                                    fontSize: 10,
                                                },
                                                {
                                                    border: [false, false, false, true],
                                                    text: shippingCompanyDesignation,
                                                    fontSize: 10,
                                                    margin: [10,0,0,0],
                                                    bold: true,
                                                }, 
                                                {
                                                    border: [false, false, false, true],
                                                    text: result[i].SHPPNG_CMPNY + ' : ' + result[i].CMPNY,
                                                    fontSize: 10,
                                                    margin: [10,0,0,0],
                                                }, 
                                            ],
                                        ]
                                    },
                                    layout: {
                                        hLineWidth: function(i, node) {
                                            return (i === 0 || i === node.table.body.length) ? 0.1 : 0.1;
                                        },
                                        vLineWidth: function(i, node) {
                                            return (i === 0 || i === node.table.widths.length) ? 0.1 : 0.1;
                                        }
                                    },      
                                },
                                {
                                    table: {
                                        widths: [550 ,200],
                                        body: [
                                            [
                                                [
                                                    {
                                                        margin: [0,10,0,0],
                                                        columns: [
                                                            {
                                                                width: 75,
                                                                text: propertyName,
                                                                fontSize: 10,
                                                                bold: true,
                                                                margin: [4,0,0,5],
                                                            },
                                                            {
                                                                width: 'auto',
                                                                text: result[i].SCEN,
                                                                fontSize: 10,
                                                                margin: [15,0,0,5],
                                                            },
                                                        ],    
                                                    },
                                                    {
                                                        margin: [0,10,0,0],
                                                        columns: [
                                                            {
                                                                width: 100,
                                                                text: invoiceContent,
                                                                fontSize: 10,
                                                                bold: true,
                                                                margin: [4,0,0,5],
                                                            },
                                                            {
                                                                width: 'auto',
                                                                text: result[i].INVICE_CNTNTS_1,
                                                                fontSize: 10,
                                                                margin: [15,0,0,5],
                                                            },
                                                        ],    
                                                    },
                                                ],
                                                [
                                                    {
                                                        alignment: 'left',
                                                        margin: [40,10,0,0],
                                                        table: {
                                                            widths: [75,50],
                                                            body: [
                                                                [
                                                                    {
                                                                        border: [true, true, false, true],
                                                                        text: invoiceFaxRequest,
                                                                        fontSize: 10,
                                                                        bold: true,
                                                                    },
                                                                    {
                                                                        border: [false, true, true, true],
                                                                        text: result[i].INVICE_FAX_RQEST + ' : ' + result[i].FAX_RQEST,
                                                                        fontSize: 10,
                                                                        margin: [20,0,0,0],
                                                                    },
                                                                ],
                                                                [
                                                                    {
                                                                        border: [true, false, false, true],
                                                                        text: faxExistence,
                                                                        fontSize: 10,
                                                                        bold: true,
                                                                    },
                                                                    {
                                                                        border: [false, false, true, true],
                                                                        text: result[i].INVICE_FAX_PRSNC + ' : ' + result[i].FAX_PRSNC,
                                                                        fontSize: 10,
                                                                        margin: [20,0,0,0],
                                                                    },
                                                                ],
                                                                [
                                                                    {
                                                                        border: [true, false, false, true],
                                                                        text: mapExistence,
                                                                        fontSize: 10,
                                                                        bold: true,
                                                                    },
                                                                    {
                                                                        border: [false, false, true, true],
                                                                        text: result[i].MAP_PRSNC + ' : ' + result[i].PRSNC,
                                                                        fontSize: 10,
                                                                        margin: [20,0,0,0],
                                                                    },
                                                                ],
                                                            ],
                                                        },
                                                        layout: 'noBorders',
                                                    },
                                                ],
                                            ],
                                        ]
                                    },
                                    layout: 'noBorders',
                                },
                                {
                                    margin:[0,10,0,0],
                                    fontSize:10,
                                    alignment: 'center',
                                    table: {
                                        headerRows: 1,
                                        widths: [20,110,160,30,80,120,60,110],
                                        body: [
                                            [
                                                { text: lineNumber, border: [false, true, false, true],},
                                                { text: productCode, border: [false, true, false, true],},
                                                { text: productName, border: [false, true, false, true],},
                                                { text: quantity, border: [false, true, false, true],},
                                                { text: unitPrice, border: [false, true, false, true],},
                                                { text: customerProductCode, border: [false, true, false, true],},
                                                { text: shippingScheduleDate, border: [false, true, false, true],},
                                                { text: orderNumber, border: [false, true, false, true],},
                                            ],
                                        ]
                                    }
                                },
                            ],
                        ],
                        [
                            {
                                fontSize:8,
                                alignment: 'center',
                                table: {
                                    widths: [20,110,160,30,80,120,60,110],
                                    body: prdctsData[i],
                                },
                            },
                        ]
                    ]
                }
            },               
            ],
            styles:{
                tableExample: {
                    margin: [0, 0, 0, 10],
                },
            },
            defaultStyle: {
                font: "ipam"
            },
        };
    if(i == 0){
        pdfData = docDefinition;
    }
    if(i >= 1){
        prdcts.push(...docDefinition.content);
    }
}
pdfData.content.push(...prdcts);
return pdfData;
}

export default {
    CreatePdf,
  }