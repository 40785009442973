import axios from "axios";
async function search(ShapingData,CstmrtName){
  try{
    var prdctcds = [];
    for(var i = 0; i < ShapingData.length;i++){
      for(var j = 0; j < ShapingData[i].length;j++){
        prdctcds.push(ShapingData[i][j].PRDCT_CD);
      }
    }
    const PrdctsrchResult = await axios.post("https://nasta-csvmngmnt.com/getCstmrPrdct",{
      CSTMR_CD: CstmrtName,  
      CSTMR_PRDCT: prdctcds,
    });
    for(var k = 0; k < ShapingData.length; k++){
      for(var l = 0; l < ShapingData[k].length; l++){
        for(var m = 0; m < PrdctsrchResult.data.length; m++){
          if(CstmrtName == '0'){
            if(ShapingData[k][l].PRDCT_CD == PrdctsrchResult.data[m].PRDCT_CD){
              ShapingData[k][l].CSTMR_PRDCT_CD = "";
              ShapingData[k][l].PRDCT_CD = PrdctsrchResult.data[m].PRDCT_CD;
              ShapingData[k][l].PRDCT_NM = PrdctsrchResult.data[m].PRDCT_NM;
              ShapingData[k][l].UNIT_PRC = PrdctsrchResult.data[m].PRCE;
            }   
          }else{
            if(ShapingData[k][l].PRDCT_CD == PrdctsrchResult.data[m]['CSTMR' + CstmrtName + '_PRDCT_CD']){
              ShapingData[k][l].CSTMR_PRDCT_CD = ShapingData[k][l].PRDCT_CD;
              ShapingData[k][l].PRDCT_CD = PrdctsrchResult.data[m].PRDCT_CD;
              ShapingData[k][l].PRDCT_NM = PrdctsrchResult.data[m].PRDCT_NM;
              ShapingData[k][l].UNIT_PRC = PrdctsrchResult.data[m].PRCE;
            }
          }
        }
      }
    } 
    return;
    }catch{
      alert("処理に失敗しました管理者に連絡して下さい。");
    }
}

async function getframe(ShapingData){
 try{
  var prdctcds = [];
  var qtys = 0;
  var frames = [];
  var scdleDt = null;
  for(var i = 0; i < ShapingData.length;i++){
    for(var j = 0; j < ShapingData[i].length;j++){
      prdctcds.push(ShapingData[i][j].PRDCT_CD);
    }
  }
  const PrdctsrchResult = await axios.post("https://nasta-csvmngmnt.com/getFrameNum",{
    CSTMR_PRDCT: prdctcds,
  });
  for(var k = 0; k < ShapingData.length; k++){
    for(var l = 0; l < ShapingData[k].length; l++){
      for(var m = 0; m < PrdctsrchResult.data.length; m++){
        if(ShapingData[k][l].PRDCT_CD == PrdctsrchResult.data[m].PRDCT_CD){
          if(ShapingData[k][l].SHPPNG_SCDLE_DT == '' || ShapingData[k][l].SHPPNG_SCDLE_DT == null){
            scdleDt = "指定なし";
          }else{
            scdleDt = ShapingData[k][l].SHPPNG_SCDLE_DT;
          }
          qtys = PrdctsrchResult.data[m].MTH_QTY * ShapingData[k][l].QTY
           frames.push(
            {
              SHPPNG_SCDLE_DT : scdleDt,
              QTY : qtys,
              MTH_QTY : PrdctsrchResult.data[m].MTH_QTY,
              CLSFCTON_NM : PrdctsrchResult.data[m].CLSFCTON_NM,
            }
          );
        }
      }
    }
  }
  var group = frames.reduce(function (result, current) {
    var element = result.find(function (p) {
      if(p.SHPPNG_SCDLE_DT === current.SHPPNG_SCDLE_DT && p.CLSFCTON_NM === current.CLSFCTON_NM){
        return p.SHPPNG_SCDLE_DT === current.SHPPNG_SCDLE_DT
      }
    });
    if (element) {
      element.MTH_QTY += current.QTY;
    } else {
      result.push({
        SHPPNG_SCDLE_DT: current.SHPPNG_SCDLE_DT,
        MTH_QTY: current.QTY,
        CLSFCTON_NM: current.CLSFCTON_NM
      });
    }
    return result;
  }, []);
  return group
 } catch{
  alert("処理に失敗しました管理者に連絡して下さい。");
 }
}
export default {
  search,
  getframe
}