const date = new Date();
var year = date.getFullYear();
var month = date.getMonth()+1;
var day = date.getDate();
var hour = date.getHours();
var minute = date.getMinutes();
var second = date.getSeconds();

var nowdate = year + '' + month + ''  + '' +day + hour + minute + second;
var nowday = year + '-' + month + '-' + day;
var yeardata =  year;
function dates(){
  return nowdate;
}
function mtchDatas() {
  if(month.toString().length == 1 && day.toString().length > 1){
    var result = year + '-' + "0" + month + '-' + day;
    return result
  }else if(month.toString().length > 1 && day.toString().length == 1){
    result = year + '-' + month + '-' + "0" + day;
    return result
  }else if(month.toString().length == 1 && day.toString().length == 1){
    result = year + '-' + "0" + month + '-' + "0" + day;
    return result
  }else{
    return nowday
  }
}
function yearres(){
  return yeardata;
}
export default {
  dates,
  yearres,
  mtchDatas
}