<template>
  <v-container style="height: auto">
    <v-card v-if="vali" outlined style="width:auto;border:1px solid #FF5252;margin: -20px 0 70px 0;" height="auto">
      <v-col lg="12" md="12">
        <p style="color:#FF5252"><v-icon color="#FF5252" style="padding:0 5px 5px 0;">mdi-alert-circle</v-icon>更新内容にエラーがあります。</p>
        <p v-for="error in ErrorMessages" :key="error.ErrorMessages">・{{error.message}}</p>
      </v-col>	
    </v-card>
    <v-row style="margin-top: -40px">
      <v-col>
        <v-dialog v-model="dialog" persistent max-width="400">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="update" color="#00ACF9" elevation="5" v-bind="attrs" v-on="on">更新</v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5" style="background-color:#00ACF9">更新</v-card-title>
            <v-card-title style="color: rgba(0,0,0,.8);font-size: 16px;">更新して宜しいですか？</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialog = false">
                cancel
              </v-btn>
              <v-btn color="green darken-1" text @click="ItemUpdate">
                ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>  
      <v-dialog v-if="parDialog == true" v-model="parDialog" persistent max-width="400">
        <v-card>
          <v-card-title v-if="compDialog == true" class="text-h5" style="background-color:#43A047"></v-card-title>
          <v-card-title v-if="errDialog == true" class="text-h5" style="background-color:#FF5252"></v-card-title>
          <v-card-title v-if="compDialog == true">更新が完了しました。</v-card-title>
          <v-card-title v-if="errDialog == true">更新出来ませんでした。<br>管理者に連絡してください。</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="router">
              ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>        
      </v-col>
    </v-row>        
    <v-form>
      <v-card style="width:auto;" height="auto" elevation="2">
        <v-row style="margin: auto;padding-bottom: 5px;">
          <v-col lg="6" md="6" >	
            <div class="inline_1 clearfix">  	
              <div class="text-center">配送区分<span>*</span></div>	
              <v-select height="25px" v-model="shppngKbn" class="styled-input split_2" item-text="CLSFCTON_NM" item-value="CLSFCTON_NUM" :items="Hskb"></v-select>	
              <div class="text-center">出荷倉庫<span>*</span></div>
              <v-select height="25px" v-model="shppngWrehs" class="styled-input split_2" item-text="CLSFCTON_NM" item-value="CLSFCTON_NUM" :items="SOKB"></v-select>	
            </div>		
            <div class="inline_1 clearfix">
              <div class="text-center">出荷予定日</div>
              <v-menu :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field class="pickers" readonly v-model="shppngScdleDt" height="25" maxlength="10" clearable v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker locale="ja" v-model="shppngScdleDt"></v-date-picker>
              </v-menu>
              <div class="text-center">着日指定<span>*</span></div>
              <v-menu :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field class="pickers" readonly v-model="dtArvlDagntn" height="25" maxlength="10" clearable v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker locale="ja" v-model="dtArvlDagntn"></v-date-picker>
              </v-menu>            
            </div>
            <div class="inline_1 clearfix">	
              <div class="text-center">客先注文No.</div>
              <v-text-field height="25px" v-model="cstmrOrdrNo" class="styled-input" type="text"></v-text-field>
              <div class="text-center">時間指定</div>	
              <v-text-field height="25px" v-model="tmArvlDagntn" class="styled-input" type="text"></v-text-field>
            </div>
            <div class="inline_1 clearfix">	
              <div class="text-center">経由先名</div>
              <v-text-field height="25px" v-model="viaNm" class="styled-input" type="text"></v-text-field>	
            </div>
            <div class="inline_1 clearfix">	
              <div class="text-center">納品先名1</div>
              <v-text-field height="25px" v-model="dlvryDstntonNm1" class="styled-input" type="text"></v-text-field>	
            </div>	
            <div class="inline_1 clearfix">	
              <div class="text-center">納品先名2</div>
              <v-text-field height="25px" v-model="dlvryDstntonNm2" class="styled-input" type="text"></v-text-field>	
            </div>	
            <div class="inline_1 clearfix">	
              <div class="text-center">郵便番号<span>*</span></div>
              <v-text-field height="25px" v-model="pstCd" class="styled-input split_2" type="text"></v-text-field>
              <div class="text-center">都道府県</div>	
              <v-autocomplete hide-no-data height="25px" item-text="PRFCTRS_NM" item-value="PRFCTR_NM" :items="PREF" v-model="prefectures" class="styled-input split_2"></v-autocomplete>
            </div>	
            <div class="inline_1 clearfix">	
              <div class="text-center">納品住所1<span>*</span></div>
              <v-text-field height="25px" v-model="dlvryAddrss1" class="styled-input" type="text"></v-text-field>	
            </div>	
            <div class="inline_1 clearfix">	
              <div class="text-center">納品住所2</div>
              <v-text-field height="25px" v-model="dlvryAddrss2" class="styled-input" type="text"></v-text-field>	
            </div>	                  
            <div class="inline_1 clearfix">	
              <div class="text-center">電話番号</div>	
              <v-text-field height="25px" v-model="phneCd" class="styled-input" type="text"></v-text-field>	
            </div>
            <div class="inline_1 clearfix">
              <div class="text-center">運賃区分<span>*</span></div>
              <v-select height="25px" v-model="shppngExpnsKbn" class="styled-input split_2" type="text" item-text="CLSFCTON_NM" item-value="CLSFCTON_NUM" :items="Uckb"></v-select>	
              <div class="text-center">運送会社</div>
              <v-select height="25px" v-model="shppngCmpny" class="styled-input split_2" type="text" item-text="CLSFCTON_NM" item-value="CLSFCTON_NUM"  :items="trsp"></v-select>
            </div>
            <div class="inline_1 clearfix">
            </div>                  
          </v-col>	
          <v-col lg="6" md="6" >	
            <div class="inline_1 clearfix">	
              <div class="text-center">物件名</div>
              <v-text-field height="25px" v-model="scen" class="styled-input" type="text"></v-text-field>              
            </div>
            <div class="inline_2 clearfix">	
              <div class="text-center small">送状FAX依頼</div>	
              <v-select height="25px" v-model="inviceFaxRqest" class="styled-input split_3" item-text="CLSFCTON_NM" item-value="CLSFCTON_NUM" :items="UMKB"></v-select>	
              <div class="text-center">送状FAX有無</div>	
              <v-select height="25px" v-model="inviceFaxPrsnc" class="styled-input split_3" item-text="CLSFCTON_NM" item-value="CLSFCTON_NUM" :items="UMKB"></v-select>
              <div class="text-center">地図有無</div>	
              <v-select height="25px" v-model="mapPrsnc" class="styled-input split_3" item-text="CLSFCTON_NM" item-value="CLSFCTON_NUM" :items="UMKB"></v-select>
            </div>	
            <div class="inline_1 clearfix">	
              <div class="text-center">送状付記内容1</div>
              <v-text-field height="25px" v-model="inviceCntnts1" class="styled-input" type="text"></v-text-field>	
            </div>                  
            <div class="inline_2_remarks clearfix">	
              <div class="text-center area">備考</div>
              <v-textarea v-model="remarks" no-resize counter class="styled-input-area"></v-textarea>
            </div>
            <div class="inline_2_remarks clearfix">	
              <div class="text-center area">備考2</div>
              <v-textarea v-model="remarks2_1" no-resize counter class="styled-input-area"></v-textarea>
            </div>                  
            <div class="inline_2 clearfix">	
              <div class="text-center">保留区分</div>	
              <v-checkbox style="position:relative;bottom:9px;left:10px" v-model="hold" label="保留" color="orange">	
                <template v-slot:label></template>	
              </v-checkbox>	
            </div>	
          </v-col>	
        </v-row>
      </v-card>
      <v-data-table :headers="headers" :items="messages" :items-per-page="itemsPerPage" disable-sort fixed-header height="260px" hide-default-footer loading-text="読込中" no-data-text="データがありません。" class="elevation-1">
        <template v-slot:item.PRDCT_CD="{ item }">
          <v-combobox height="25px" item-text="PRDCT_CD" item-value="PRDCT_CD" :items="PRDC" v-model="item.PRDCT_CD" class="styled-input_2" @change="srchPrdcts(item.PRDCT_CD)"></v-combobox>
        </template>
        <template v-slot:item.PRDCT_NM="{ item }">
          <v-text-field height="25px" v-model="item.PRDCT_NM" class="styled-input_2" type="text"></v-text-field>
        </template>
        <template v-slot:item.QTY="{ item }">
          <v-text-field height="25px" v-model="item.QTY" class="styled-input_2 " type="number"></v-text-field>
        </template>
        <template v-slot:item.CSTMR_PRDCT_CD="{ item }">
          <v-text-field height="25px" v-model="item.CSTMR_PRDCT_CD" class="styled-input_2" type="text"></v-text-field>
        </template>
        <template v-slot:item.UNIT_PRC="{ item }">
          <v-text-field height="25px" v-model="item.UNIT_PRC" class="styled-input_2" type="text" suffix="円"></v-text-field>
        </template>
      </v-data-table>
    </v-form>
    <v-row style="margin-bottom: 70px">
      <v-col>    
        <div class="right-btns">
          <v-dialog v-model="delDialog" persistent max-width="400">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="white--text btn" v-bind="attrs" v-on="on" color="error" >削除</v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5" style="background-color:#FF5252;">削除</v-card-title>
              <v-card-title class="pa-5" style="color: rgba(0,0,0,.8);font-size: 16px;">削除して宜しいですか？</v-card-title>
              <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="delDialog = false">
                  cancel
                </v-btn>
                <v-btn color="green darken-1" text @click="selectdel">
                  ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-if="compDelDialog == true" v-model="compDelDialog" persistent max-width="400">
            <v-card>
              <v-card-title v-if="compDelDialog == true" class="text-h5" style="background-color:#43A047"></v-card-title>
              <v-card-title v-if="errDelDialog == true" class="text-h5" style="background-color:#FF5252"></v-card-title>
              <v-card-title v-if="compDelDialog == true">削除が完了しました。</v-card-title>
              <v-card-title v-if="errDelDialog == true">削除出来ませんでした。<br>管理者に連絡してください。</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="router">
                  ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>      
      </v-col>
    </v-row>               
  </v-container>
</template>

<script>
import CleateItem from "../store/modules/cleateItem.js";
import select from './javascript/selectData.js';
import update from './javascript/updates.js';
import updateValidats from './javascript/updateValidats.js';
import axios from "axios";
export default {
  name: "Items",
  data: () => ({
    messages: [],
    updatedatas: [],
    headers: [
      { text: '製品コード', align:'center',width:'15%', value:'PRDCT_CD'},
      { text: '品名',align:'center', width:'40%',value: 'PRDCT_NM'},
      { text: '客先製品コード',align:'center', width:'25%',value: 'CSTMR_PRDCT_CD'},
      { text: '受注数',align:'center', width:'6%',value: 'QTY'},
      { text: '単価',align:'center', width:'8%',value: 'UNIT_PRC'},
    ],
    
    itemsPerPage: 50,
    parm : null,
    parDialog : false,
    compDialog : false,
    errDialog : false,
    delDialog : false,
    errDelDialog : false,
    compDelDialog : false,
    dialog: false,
    userId: null,
    vali: false,
    prefectures : null,
    remarks: null,
    remarks2_1: null,
    mngmntNum: null,
    hold: null,
    dlvrySlpNo : null,
    shppngExpnsKbn : null,
    shppngKbn : null,
    pstCd : null,
    phneCd : null,
    shppngCmpnySrvcs : null,
    inviceCntnts1 : null,
    dtArvlDagntn : null,
    tmArvlDagntn : null,
    shppngCmpny : null,
    cstmrOrdrNo : null,
    viaNm : null,
    dlvryDstntonNm1 : null,
    dlvryDstntonNm2 : null,
    dlvryAddrss1 : null,
    dlvryAddrss2 : null,
    scen : null,
    shppngWrehs : null,
    shppngScdleDt : null,
    inviceFaxRqest : null,
    inviceFaxPrsnc : null,
    mapPrsnc : null,
  }),
  computed: {

    Hskb(){
      return CleateItem.state.hskbs;
    },
    Uckb(){
      return CleateItem.state.uckbs;
    },
    trsp(){
      return CleateItem.state.trsps;
    },
    jukb2(){
      return CleateItem.state.jukb2s;
    },
    SOKB(){
      return CleateItem.state.sokbs;
    },
    UMKB(){
      return CleateItem.state.umkbs;
    },
    PREF(){
      return CleateItem.state.prefs;
    },
    PRDC(){
      return CleateItem.state.prdcts;
    }
  },
  async mounted() {
    this.$store.dispatch("cleateItem/Updateclsfcton");
    this.$store.dispatch("cleateItem/Prefectures");
    this.$store.dispatch("cleateItem/Prdcts");
    var routePath = this.$route.path;
    var parm = routePath.substr(8,100);
    this.parm = parm;
    var datas = {PARM: parm};
    try{
    select.select(datas).then(result => {
      this.messages = result;
      this.dlvrySlpNo = this.messages[0].DLVRY_SLP_NO
      this.mngmntNum = this.messages[0].MNGMNT_NUM
      this.shppngKbn = this.messages[0].SHPPNG_KBN
      this.shppngWrehs = this.messages[0].SHPPNG_WREHS
      if(this.messages[0].SHPPNG_SCDLE_DT == '0'){
        this.messages[0].SHPPNG_SCDLE_DT = ''
      }
      this.shppngScdleDt = this.messages[0].SHPPNG_SCDLE_DT
      this.cstmrOrdrNo = this.messages[0].CSTMR_ORDR_NO
      this.viaNm = this.messages[0].VIA_NM
      this.dlvryDstntonNm1 = this.messages[0].DLVRY_DSTNTON_NM_1
      this.dlvryDstntonNm2 = this.messages[0].DLVRY_DSTNTON_NM_2
      this.pstCd = this.messages[0].PST_CD
      this.prefectures = this.messages[0].PREFECTURES
      this.dlvryAddrss1 = this.messages[0].DLVRY_ADDRSS_1
      this.dlvryAddrss2 = this.messages[0].DLVRY_ADDRSS_2
      this.phneCd = this.messages[0].PHNE_CD
      this.scen = this.messages[0].SCEN
      this.dtArvlDagntn = this.messages[0].DT_ARVL_DAGNTN
      this.tmArvlDagntn = this.messages[0].TM_ARVL_DAGNTN
      this.shppngExpnsKbn = this.messages[0].SHPPNG_EXPNS_KBN
      this.shppngCmpny = this.messages[0].SHPPNG_CMPNY
      this.inviceFaxRqest = this.messages[0].INVICE_FAX_RQEST
      this.inviceFaxPrsnc = this.messages[0].INVICE_FAX_PRSNC
      this.mapPrsnc = this.messages[0].MAP_PRSNC
      this.inviceCntnts1 = this.messages[0].INVICE_CNTNTS_1
      this.remarks = this.messages[0].REMARKS
      this.remarks2_1 = this.messages[0].REMARKS2
      if(this.messages[0].STTS_KBN != 1){
        this.hold = false;
      }else {
        this.hold = this.messages[0].STTS_KBN
      }
    });
    }catch{
      alert("処理に失敗しました。");
    }
  },  
  methods: {
   async srchPrdcts(val) {
      if(val.PRDCT_CD != null){
       const srchPrdcts = await axios.post('https://nasta-csvmngmnt.com/getSrchPrdcts',{PRDCT_CD : val.PRDCT_CD});
        for(var i = 0; i<this.messages.length; i++){
          if(val.PRDCT_CD == this.messages[i].PRDCT_CD.PRDCT_CD){
            this.messages[i].PRDCT_NM = srchPrdcts.data[0].PRDCT_NM;
            this.messages[i].UNIT_PRC = srchPrdcts.data[0].PRCE;
            this.messages[i].PRDCT_CD = this.messages[i].PRDCT_CD.PRDCT_CD;
          }
        }
      }
    },    
    router(){
      this.compDelDialog = false;
      this.$router.push("/orders");
    },    
    async ItemUpdate() {
      try{
        this.updatedatas = [];
        for(var i = 0; i < this.messages.length; i++){
          if(this.hold){
            this.hold = 1
          }else{
            this.hold = 0
          }
          this.updatedatas.push(
            {
              SHPPNG_KBN : this.shppngKbn,
              SHPPNG_WREHS : this.shppngWrehs,
              SHPPNG_SCDLE_DT : this.shppngScdleDt,
              CSTMR_ORDR_NO : this.cstmrOrdrNo,
              VIA_NM : this.viaNm,
              DLVRY_DSTNTON_NM_1 : this.dlvryDstntonNm1,
              DLVRY_DSTNTON_NM_2 : this.dlvryDstntonNm2,
              PST_CD : this.pstCd,
              PREFECTURES : this.prefectures,
              DLVRY_ADDRSS_1 : this.dlvryAddrss1,
              DLVRY_ADDRSS_2 : this.dlvryAddrss2,
              PHNE_CD : this.phneCd,
              SCEN : this.scen,
              DT_ARVL_DAGNTN : this.dtArvlDagntn,
              TM_ARVL_DAGNTN : this.tmArvlDagntn,
              SHPPNG_EXPNS_KBN : this.shppngExpnsKbn,
              SHPPNG_CMPNY : this.shppngCmpny,
              INVICE_FAX_RQEST : this.inviceFaxRqest,
              INVICE_FAX_PRSNC : this.inviceFaxPrsnc,
              MAP_PRSNC : this.mapPrsnc,
              INVICE_CNTNTS_1 : this.inviceCntnts1,
              REMARKS : this.remarks,
              REMARKS2_1 : this.remarks2_1,
              STTS_KBN : this.hold,
              PRDCT_CD : this.messages[i].PRDCT_CD,
              PRDCT_NM : this.messages[i].PRDCT_NM,
              CSTMR_PRDCT_CD : this.messages[i].CSTMR_PRDCT_CD,
              QTY : this.messages[i].QTY,
              UNIT_PRC : this.messages[i].UNIT_PRC,
              MNGMNT_NUM : this.mngmntNum,
              WPRDCT_CD : this.messages[i].ITM_NO,
            }
          );
        }        
      this.ErrorMessages = [];
      var val = null;
      val = updateValidats.validat(this.updatedatas);
      var updateResult = null;
      this.dialog = false;
      if(val == true){
        /*
        if(this.shppngScdleDt == null){
          this.shppngScdleDt = "";
        }
        if(this.dtArvlDagntn == null){
          this.dtArvlDagntn = "";
        }*/
        update.updates(
            this.cstmrOrdrNo,this.shppngKbn,this.shppngWrehs,this.shppngScdleDt,
            this.cstmrOrdrNo,this.viaNm,this.dlvryDstntonNm1,
            this.dlvryDstntonNm2,this.pstCd,this.prefectures,
            this.dlvryAddrss1,this.dlvryAddrss2,this.phneCd,
            this.scen,this.dtArvlDagntn,this.tmArvlDagntn,
            this.shppngExpnsKbn,this.shppngCmpny,this.inviceFaxRqest,
            this.inviceFaxPrsnc,this.mapPrsnc,this.inviceCntnts1,
            this.remarks,this.remarks2_1,this.hold,this.$store.state.login.userId,this.mngmntNum,
            this.messages
          ).then(result => {
            updateResult = result;
            if(updateResult){
              this.vali = false;
              this.dialog = false;
              this.parDialog = true;
              this.compDialog = true;
            }else{
              this.dialog = false;
              this.parDialog = true;
              this.errDialog = true;
           }
          });        
      }else{
        this.vali = true;
        this.ErrorMessages = val;
      }
      }catch{
        this.errDialog = true;
        alert("更新処理に失敗しました。");
      }
    },
    async selectdel() {
    var delresult = null;
    const deleteresult = await axios.post('https://nasta-csvmngmnt.com/deleteAsMpgs',{MNGMNT_NUM : this.parm, USER_ID : this.$store.state.login.userId});
    delresult = deleteresult;
    if(delresult.data == "ok"){
      this.delDialog = false;
      this.compDelDialog = true;
      this.selected = [];
    }else{
      this.errDelDialog = true;
    }
    this.$store.dispatch("nallow/defaultdata",{USER_ID : this.$store.state.login.userId});
    },    
  },
};
</script>

<style scoped>

span {
  color: red;
}
.text-center {
  width: 120px;
  font-size: 15px;
  padding-right: 5px;
  line-height: 45px;
  text-align: left;
  height: 45px;
  background-color: #EFF8FF;
}
.area {
  margin: auto auto;
  width: 120px;
  font-size: 16px;
  padding-right: 5px;
  line-height: 35px;
  height: 100%;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th  {
    font-size: 20px !important;
}
.v-data-table-header{
    font-size: 20px !important;
}
th.text-start{
   font-size: 20px !important;
}
.clearfix::after {
  content:" ";
  display:block;
  clear:both;
}
.inline_1{
  display: flex;
  height: 45px;
  margin-bottom: 1px;
}
.container{
  height: 420px;
}

.styled-input {
  height: 35px;
font-size: 16px;
}
.split_2{
  width: 10%;
}
.split_3{
  width: 10%;
}
.styled-input-area {
width: auto;
margin: 0;
font-size: 16px;
height: 185px;
background-color: #ffffff;
}

.styled-input_2 {
  height: 35px;
  font-size: 16px;
}

.small {
  min-width: 100px;
}

.inline_2 {
  display: flex;
  height: 45px;
}
.inline_2_remarks {
  display: flex;
  margin-bottom: 1px;
  background-color: #EFF8FF;
}

.update{
  height: 60px;
  float: right;
  width: 100px;
  right: 15px;
  color: #ffffff;
  margin-bottom:50px;
}

.elevation-1{
  max-width: 100%;
  height: auto;
  margin: 50px auto 20px auto;
}

.pickers{
  width: 185px;
}


</style>

