function csvSplit(line) {
  var c = "";
  var s = new String();
  var data = new Array();
  var singleQuoteFlg = false;
  for (var i = 0; i < line.length; i++) {
    c = line.charAt(i);
    if (c == "," && !singleQuoteFlg) {
      data.push(s.toString());
      s = "";
    }else if (c == "\r" && !singleQuoteFlg) {
      data.push(s.toString());
      s = "";
    } else if (c == "," && singleQuoteFlg) {
      s = s + c;
    } else if (c == '"') {
      singleQuoteFlg = !singleQuoteFlg;
    } else {
      s = s + c;
    }
  }
  return data;
}

function indention(reader) {
  var temp =  '';
  var dblflg = false;
  var result = [];
  for(var j = 0; j < reader.length; j++){
    if(!dblflg && reader[j] == "\""){
      dblflg = true
      temp += "";
    }else if(dblflg == true && reader[j] == "\n"){
        temp += "　";
    }else if(dblflg == true && reader[j] == ","){
      temp += "";
  }else if(dblflg == true && reader[j] == "\r"){
        temp += "";
    }else if(dblflg == true && reader[j] == "\""){
      dblflg = false
      temp += "";
    }else{
      temp += reader.charAt(j);
    }
  } 
  result = temp.split("\n");
  return result
}
export default {
  indention,
  csvSplit
  }