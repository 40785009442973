<template>      
  <v-main style="min-height: 100%;">
  <menuBar />
  <cleatItem />
  <Footer />
  </v-main>
</template>

<script>
import menuBar from "@/components/menuBar.vue";
import Footer from "@/components/footer.vue";
import cleatItem from '@/components/cleatItem.vue';

export default {
  name: "imports",
  components: {
    menuBar,
    Footer,
    cleatItem
  },
};
</script>