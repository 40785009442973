import date from './date.js';
function CreateCsv(csvresult){
    var c = 0;
    var csvOutput = [];
    for(var j = 0; j < csvresult.length; j++){
        var csv = '\ufeff' + '受渡伝票No,ItemNo,同梱付記依頼,得意先CD,受注区分,受注日,納期,出荷予定手番,出荷予定日,出荷倉庫,受注担当者CD,配送区分,明細区分,客先注文No,運賃区分,日付着指定,時間着指定,運送会社,運送会社サービス,送状付記内容1,送状付記内容2,依頼者名,経由名,納品先CD,納品先名1,納品先名2,郵便番号,納品先住所1,納品先住所2,現場,担当者名,電話番号,送状FAX依頼,送状FAX有無,地図有無,製品CD,品名,製品詳細,受注数,単価,出荷単位\n'
        csvresult[j].forEach(el => {
          var line = el['DLVRY_SLP_NO'] + ',' + el['ITM_NO'] + ',' + '' + ',' + el['CSTMR_CD'] + ',' + el['ORDR_KBN'] + ',' + '0' + ',' + el['DLVRY_TM'] + ',' + el['SHPPNG_SCDLE_TRN'] + ',' + el['SHPPNG_SCDLE_DT'] + ',' + el['SHPPNG_WREHS'] + ',' + '' + ',' + el['SHPPNG_KBN'] + ',' + '' + ',' + el['CSTMR_ORDR_NO'] + ',' + el['SHPPNG_EXPNS_KBN'] + ',' + el['DT_ARVL_DAGNTN'] + ',' + el['TM_ARVL_DAGNTN'] + ',' + el['SHPPNG_CMPNY'] + ',' + '' + ',' + el['INVICE_CNTNTS_1'] + ',' + '' + ',' + el['RQEST_NM'] + ',' + el['VIA_NM'] + ',' + '' + ',' + el['DLVRY_DSTNTON_NM_1'] + ',' + el['DLVRY_DSTNTON_NM_2'] + ',' + el['PST_CD'] + ',' + el['DLVRY_ADDRSS_1'] + ',' + el['DLVRY_ADDRSS_2'] + ',' + el['SCEN'] + ',' + '' + ',' + el['PHNE_CD'] + ',' + el['INVICE_FAX_RQEST'] + ',' + el['INVICE_FAX_PRSNC'] + ',' + el['MAP_PRSNC'] + ',' + el['PRDCT_CD'] + ',' + el['PRDCT_NM'] + ',' + '' + ',' + el['QTY'] + ',' + el['UNIT_PRC'] + '\n'
          if(c === 0){
            csv += line
            csvOutput += csv; 
          }else{
            csv = line
            csvOutput += csv;
          }
          ++c;
        })
      }
      let blob = new Blob([csvOutput], { type: 'text/csv' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'OrderInstruction_JUCHU'+date.dates()+'.csv'
return link;
}

export default {
    CreateCsv,
  }