import nowdate from './date.js';
function uploads(cstmrMngmntNum,cstmrtName,workers,header){
  var initiaLizeData = [];
  var result = [];
  var tmpData = [];
  var shapingData = [];
  var selectnum = [];
  try{
    for(var s = 0; s < cstmrMngmntNum.length; s++){
      if(cstmrtName == cstmrMngmntNum[s].CSTMR_MNGMNT_NUM){
      selectnum = cstmrMngmntNum[s];
      }
    }
    for(var i = 0; i < workers.length; i++){
      initiaLizeData.push(
        {
          CSTMR_CD : selectnum.CSTMR_CD,
          DLVRY_SLP_NO : undefinedToEmpty(workers[i][Number(selectnum.DLVRY_SLP_NO)]) + nowdate.dates(),
          IDNTFCTON_CD : undefinedToEmpty(workers[i][Number(selectnum.IDNTFCTON_CD)]), 
          ITM_NM : undefinedToEmpty(workers[i][Number(selectnum.ITM_NO)]),
          SHPPNG_EXPNS_KBN : undefinedToEmpty(workers[i][Number(selectnum.SHPPNG_EXPNS_KBN)]),
          SHPPNG_KBN : undefinedToEmpty(workers[i][Number(selectnum.SHPPNG_KBN)]),
          RQEST_NM : undefinedToEmpty(workers[i][Number(selectnum.RQEST_NM)]),
          PST_CD : undefinedToEmpty(workers[i][Number(selectnum.PST_CD)]),
          PREFECTURES : undefinedToEmpty(workers[i][Number(selectnum.PREFECTURES)]),
          PHNE_CD : undefinedToEmpty(workers[i][Number(selectnum.PHNE_CD)]),
          INVICE_CNTNTS_1 : undefinedToEmpty(workers[i][Number(selectnum.INVICE_CNTNTS_1)]),
          DT_ARVL_DAGNTN : slashToHyphen(undefinedToEmpty(workers[i][Number(selectnum.DT_ARVL_DAGNTN)])),
          TM_ARVL_DAGNTN : slashToHyphen(undefinedToEmpty(workers[i][Number(selectnum.TM_ARVL_DAGNTN)])),
          SHPPNG_CMPNY : undefinedToEmpty(workers[i][Number(selectnum.SHPPNG_CMPNY)]),
          CSTMR_ORDR_NO : undefinedToEmpty(workers[i][Number(selectnum.CSTMR_ORDR_NO)]),
          ORDR_KBN : undefinedToEmpty(workers[i][Number(selectnum.ORDR_KBN)]),
          DLVRY_TM : undefinedToEmpty(workers[i][Number(selectnum.DLVRY_TM)]),
          SHPPNG_SCDLE_TRN : undefinedToEmpty(workers[i][Number(selectnum.SHPPNG_SCDLE_TRN)]),
          VIA_NM : undefinedToEmpty(workers[i][Number(selectnum.VIA_NM)]),
          DLVRY_DSTNTON_NM_1 : undefinedToEmpty(workers[i][Number(selectnum.DLVRY_DSTNTON_NM_1)]),
          DLVRY_DSTNTON_NM_2 : undefinedToEmpty(workers[i][Number(selectnum.DLVRY_DSTNTON_NM_2)]),
          DLVRY_ADDRSS_1 : undefinedToEmpty(workers[i][Number(selectnum.DLVRY_ADDRSS_1)]),
          DLVRY_ADDRSS_2 : undefinedToEmpty(workers[i][Number(selectnum.DLVRY_ADDRSS_2)]),
          SCEN : undefinedToEmpty(workers[i][Number(selectnum.SCEN)]),
          SHPPNG_WREHS : undefinedToEmpty(workers[i][Number(selectnum.SHPPNG_WREHS)]),
          SHPPNG_SCDLE_DT : undefinedToEmpty(workers[i][Number(selectnum.SHPPNG_SCDLE_DT)]),
          INVICE_FAX_RQEST : undefinedToEmpty(workers[i][Number(selectnum.INVICE_FAX_RQEST)]),
          INVICE_FAX_PRSNC : undefinedToEmpty(workers[i][Number(selectnum.INVICE_FAX_PRSNC)]),
          MAP_PRSNC : undefinedToEmpty(workers[i][Number(selectnum.MAP_PRSNC)]),
          PRDCT_CD : undefinedToEmpty(workers[i][Number(selectnum.PRDCT_CD)]),
          PRDCT_NM : undefinedToEmpty(workers[i][Number(selectnum.PRDCT_NM)]),
          CSTMR_PRDCT_CD : '',
          QTY : undefinedToEmpty(workers[i][Number(selectnum.QTY)]),
          SHPPNG_UNIT_CD : undefinedToEmpty(workers[i][Number(selectnum.SHPPNG_UNIT_CD)]),
          UNIT_PRC : undefinedToEmpty(workers[i][Number(selectnum.UNIT_PRC)]),
          REMARKS  : remarkMolding(undefinedToEmpty(workers[i][Number(selectnum.REMARKS_1)])),
          REMARKS2_1  : dataMolding(remarksUndefinedToNull(header[0][Number(selectnum.REMARKS_2_1)]) + undefinedToNull(workers[i][Number(selectnum.REMARKS_2_1)]),remarksUndefinedToNull(header[0][Number(selectnum.REMARKS_2_2)]) + undefinedToNull(workers[i][Number(selectnum.REMARKS_2_2)]),remarksUndefinedToNull(header[0][Number(selectnum.REMARKS_2_3)]) + undefinedToNull(workers[i][Number(selectnum.REMARKS_2_3)]), 
          remarksUndefinedToNull(header[0][Number(selectnum.REMARKS_2_4)]) + undefinedToNull(workers[i][Number(selectnum.REMARKS_2_4)]),remarksUndefinedToNull(header[0][Number(selectnum.REMARKS_2_5)]) + undefinedToNull(workers[i][Number(selectnum.REMARKS_2_5)]),remarksUndefinedToNull(header[0][Number(selectnum.REMARKS_2_6)]) + undefinedToNull(workers[i][Number(selectnum.REMARKS_2_6)]),
          remarksUndefinedToNull(header[0][Number(selectnum.REMARKS_2_7)]) + undefinedToNull(workers[i][Number(selectnum.REMARKS_2_7)]),remarksUndefinedToNull(header[0][Number(selectnum.REMARKS_2_8)]) + undefinedToNull(workers[i][Number(selectnum.REMARKS_2_8)]),remarksUndefinedToNull(header[0][Number(selectnum.REMARKS_2_9)]) + undefinedToNull(workers[i][Number(selectnum.REMARKS_2_9)]),
          remarksUndefinedToNull(header[0][Number(selectnum.REMARKS_2_10)]) + undefinedToNull(workers[i][Number(selectnum.REMARKS_2_10)])),
        }
      );
      if(initiaLizeData[i].SHPPNG_KBN == ''){
        initiaLizeData[i].SHPPNG_KBN = '3'
      }
      if(initiaLizeData[i].SHPPNG_EXPNS_KBN == ''){
        initiaLizeData[i].SHPPNG_EXPNS_KBN = '1'
      }
      if(initiaLizeData[i].TM_ARVL_DAGNTN == ''){
        initiaLizeData[i].TM_ARVL_DAGNTN = 'AM'
      }
      if(initiaLizeData[i].SHPPNG_WREHS == ''){
        initiaLizeData[i].SHPPNG_WREHS = '1'
      }
      if(initiaLizeData[i].INVICE_FAX_RQEST == ''){
        initiaLizeData[i].INVICE_FAX_RQEST = '0'
      }
      if(initiaLizeData[i].INVICE_FAX_PRSNC == ''){
        initiaLizeData[i].INVICE_FAX_PRSNC = '0'
      }
      if(initiaLizeData[i].MAP_PRSNC == ''){
        initiaLizeData[i].MAP_PRSNC = '0'
      }
      if(cstmrtName == 2){
        var address,
            address_2;
        initiaLizeData[i].DLVRY_DSTNTON_NM_2 = '不在時必ずＴＥＬして下さい。'
        if(initiaLizeData[i].DLVRY_ADDRSS_1.indexOf("〒") != -1){
          address = initiaLizeData[i].DLVRY_ADDRSS_1.substring(initiaLizeData[i].DLVRY_ADDRSS_1.indexOf("〒") + 1, 9);
          address_2 = initiaLizeData[i].DLVRY_ADDRSS_1.substring(initiaLizeData[i].DLVRY_ADDRSS_1.indexOf("〒"), 9)+"　";
          initiaLizeData[i].PST_CD = address;
          initiaLizeData[i].DLVRY_ADDRSS_1 = initiaLizeData[i].DLVRY_ADDRSS_1.replace(address_2,"");
          initiaLizeData[i].DLVRY_DSTNTON_NM_1 = initiaLizeData[i].DLVRY_DSTNTON_NM_1.replace("株式会社","（株）");
          initiaLizeData[i].DLVRY_DSTNTON_NM_2 = initiaLizeData[i].DLVRY_DSTNTON_NM_2.replace("株式会社","（株）");
          initiaLizeData[i].DLVRY_DSTNTON_NM_1 = initiaLizeData[i].DLVRY_DSTNTON_NM_1.replace("有限会社","（有）");
          initiaLizeData[i].DLVRY_DSTNTON_NM_2 = initiaLizeData[i].DLVRY_DSTNTON_NM_2.replace("有限会社","（有）");
          initiaLizeData[i].DLVRY_ADDRSS_1 = replaceHalfToFull(initiaLizeData[i].DLVRY_ADDRSS_1);
          initiaLizeData[i].SCEN = replaceHalfToFull(initiaLizeData[i].SCEN);
        }
      }
    }
     result = initiaLizeData.filter((element, index, self) => 
      self.findIndex(e => e.IDNTFCTON_CD === element.IDNTFCTON_CD) === index
    );
    for(var d = 0; d < result.length; d++){
      for(var j = 0;j < initiaLizeData.length; j++){
        if(result[d].IDNTFCTON_CD === initiaLizeData[j].IDNTFCTON_CD){
          tmpData.push(
            initiaLizeData[j],
            );
        }else {
          continue;
        }
      }
      shapingData.push(...[
        tmpData,
      ]);
      tmpData = [];
    }
    }catch{
      alert("処理に失敗しました管理者に連絡して下さい。");
    }
    return { shapingData , initiaLizeData, result };
}

function undefinedToNull(remarks) {
  if(remarks == undefined){
    return null
  }
  return "【" + remarks + "】";
}

function undefinedToEmpty(contents) {
  if(contents == undefined){
    return ''
  }
  return contents;
}
function remarksUndefinedToNull(contents) {
  if(contents == undefined){
    return null
  }
  return contents + " : ";
}
function slashToHyphen(date) {
  var molDate = '';
  var year = '';
  var monthAndDay = '';
  var yearAndmonth = '';
  var day = '';
  var zero = '0';
  if(date.lastIndexOf('/') > 0){
    molDate = date.replace(/\//g,"-");
  }else{
    return date
  }
  if(molDate.charAt(6) == "-"){
    year = molDate.slice(0,5);
    monthAndDay = molDate.slice(5);
    molDate = year + zero + monthAndDay;
  }
  if(molDate.length == 9){
    yearAndmonth = molDate.slice(0,8);
    day = molDate.slice(8);
    molDate = yearAndmonth + zero + day;
    return molDate
  }else{
    return molDate
  }
}

function remarkMolding(str) {
  var molstr = '';
  molstr = str.replace(/　/g,"\n");// eslint-disable-line
  return molstr
}

function dataMolding(REMARKS_2_1,REMARKS_2_2,REMARKS_2_3,REMARKS_2_4,REMARKS_2_5,REMARKS_2_6,REMARKS_2_7,REMARKS_2_8,REMARKS_2_9,REMARKS_2_10) {
  var before = nullOrEmpty(REMARKS_2_1) + nullOrEmpty(REMARKS_2_2) + nullOrEmpty(REMARKS_2_3) + nullOrEmpty(REMARKS_2_4) + nullOrEmpty(REMARKS_2_5) + nullOrEmpty(REMARKS_2_6) + nullOrEmpty(REMARKS_2_7) + nullOrEmpty(REMARKS_2_8) + nullOrEmpty(REMARKS_2_9) + nullOrEmpty(REMARKS_2_10);
  if(before == 0){
    before = '';
    return before
  }else{
    var before2 = before.replace(/null/g, '');
    var after = before2.replace(/,/g, '\n');
    delete after.lastIndexOf('\n');
    return after
  }
}

function nullOrEmpty(str) {
  var res = '';
  if(str == '' || str == null || str == '　'){
    return null
  }else{
    res = str + ","
  }
  return res
}
export default {
  uploads,
}

function replaceHalfToFull(str){
  return str.replace(/[!-~]/g, function(s){
    return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
  });
}