
import axios from 'axios'
import List from "../../components/list.vue"
import Narrow from "../../components/narrow.vue"

export default {
  namespaced: true,
  state: {
    messages: [],
    srchmessages: [],
    csvkbs: [],
    ptkbs: [],
    rgkbs: [],
    stskbs: [],
    cstmrs: [],
    users: [],
    ST_RGSTRTN_DT : null,
    ED_RGSTRTN_DT : null,
    VIA_NM : null,
    SCEN : null,
    CSV_OUTPT_FLG : null,
    ST_SHPPNG_SCDLE_DT : null,
    ED_SHPPNG_SCDLE_DT : null,
    CSTMR_ORDR_NO : null,
    DLVRY_DSTNTON_NM : null,
    DWNLD_FLG : null,
    ST_DLVRY_DT : null,
    ED_DLVRY_DT : null,
    PRDCT_CD : null,
    DLVRY_ADDRSS : null,
    ORDR_NUM : null,
    CSTMR_CD : null,
    RGSTRTN_USR_CD : null,
    STTS_KBN : null,
  },
  mutations: {
    output(state, nallowList) {
      state.messages = nallowList;
    },
    srchoutput(state, nallowList) {
      state.srchmessages = nallowList;
    },
    srchitm(state, param) {
      state.ST_RGSTRTN_DT = param.ST_RGSTRTN_DT;
      state.ED_RGSTRTN_DT = param.ED_RGSTRTN_DT;
      state.VIA_NM = param.VIA_NM;
      state.SCEN = param.SCEN;
      state.CSV_OUTPT_FLG = param.CSV_OUTPT_FLG;
      state.ST_SHPPNG_SCDLE_DT = param.ST_SHPPNG_SCDLE_DT;
      state.ED_SHPPNG_SCDLE_DT = param.ED_SHPPNG_SCDLE_DT;
      state.CSTMR_ORDR_NO = param.CSTMR_ORDR_NO;
      state.DLVRY_DSTNTON_NM = param.DLVRY_DSTNTON_NM;
      state.DWNLD_FLG = param.DWNLD_FLG;
      state.ST_DLVRY_DT = param.ST_DLVRY_DT;
      state.ED_DLVRY_DT = param.ED_DLVRY_DT;
      state.PRDCT_CD = param.PRDCT_CD;
      state.DLVRY_ADDRSS = param.DLVRY_ADDRSS;
      state.ORDR_NUM = param.ORDR_NUM;
      state.CSTMR_CD = param.CSTMR_CD;
      state.RGSTRTN_USR_CD = param.RGSTRTN_USR_CD;
      state.STTS_KBN = param.STTS_KBN;
    },
    CSVKB(state, csvkbList) {
      state.csvkbs.push(csvkbList);
    },
    PTKB(state, ptkbList) {
      state.ptkbs.push(ptkbList);
    },
    RGKB(state, rgkbList) {
      state.rgkbs.push(rgkbList);
    },
    STSKB(state, stskbList) {
      state.stskbs.push(stskbList);
    },
    CSTMR(state, cstmrList) {
      state.cstmrs = cstmrList;
    },
    USERS(state, users) {
      state.users = users;
    },
  }, 
  actions: {
    async nallow({ commit }, { ST_RGSTRTN_DT, ED_RGSTRTN_DT, VIA_NM, SCEN, CSV_OUTPT_FLG, ST_SHPPNG_SCDLE_DT, ED_SHPPNG_SCDLE_DT, CSTMR_ORDR_NO, DLVRY_DSTNTON_NM, DWNLD_FLG, ST_DLVRY_DT, ED_DLVRY_DT, PRDCT_CD, DLVRY_ADDRSS, ORDR_NUM, CSTMR_CD, RGSTRTN_USR_CD, STTS_KBN }) {
      const param = {
        ST_RGSTRTN_DT : ST_RGSTRTN_DT,
        ED_RGSTRTN_DT : ED_RGSTRTN_DT,
        VIA_NM : VIA_NM,
        SCEN : SCEN,
        CSV_OUTPT_FLG : CSV_OUTPT_FLG,
        ST_SHPPNG_SCDLE_DT : ST_SHPPNG_SCDLE_DT,
        ED_SHPPNG_SCDLE_DT : ED_SHPPNG_SCDLE_DT,
        CSTMR_ORDR_NO : CSTMR_ORDR_NO,
        DLVRY_DSTNTON_NM : DLVRY_DSTNTON_NM,
        DWNLD_FLG : DWNLD_FLG,
        ST_DLVRY_DT : ST_DLVRY_DT,
        ED_DLVRY_DT : ED_DLVRY_DT,
        PRDCT_CD : PRDCT_CD,
        DLVRY_ADDRSS : DLVRY_ADDRSS,
        ORDR_NUM : ORDR_NUM,
        CSTMR_CD : CSTMR_CD,
        RGSTRTN_USR_CD : RGSTRTN_USR_CD,
        STTS_KBN : STTS_KBN,
      }
      try {
        const nallowList = await axios.post('https://nasta-csvmngmnt.com/getSearchAs', param);
        if(nallowList.data == 'NG'){
          alert("処理に失敗しました。\n管理者に連絡して下さい。");
        }else{
          commit('output', nallowList.data);
          commit('srchitm', param);
          List.computed.Installation();
          return;
        }
      }catch {
        alert('エラーが発生しました。\n管理者へお問合せ下さい。');
        return;
      }
    },
    async dwnldnallow({ commit }) {
      const param = {
        ST_RGSTRTN_DT : this.state.nallow.ST_RGSTRTN_DT,
        ED_RGSTRTN_DT : this.state.nallow.ED_RGSTRTN_DT,
        VIA_NM : this.state.nallow.VIA_NM,
        SCEN : this.state.nallow.SCEN,
        CSV_OUTPT_FLG : this.state.nallow.CSV_OUTPT_FLG,
        ST_SHPPNG_SCDLE_DT : this.state.nallow.ST_SHPPNG_SCDLE_DT,
        ED_SHPPNG_SCDLE_DT : this.state.nallow.ED_SHPPNG_SCDLE_DT,
        CSTMR_ORDR_NO : this.state.nallow.CSTMR_ORDR_NO,
        DLVRY_DSTNTON_NM : this.state.nallow.DLVRY_DSTNTON_NM,
        DWNLD_FLG : this.state.nallow.DWNLD_FLG,
        ST_DLVRY_DT : this.state.nallow.ST_DLVRY_DT,
        ED_DLVRY_DT : this.state.nallow.ED_DLVRY_DT,
        PRDCT_CD : this.state.nallow.PRDCT_CD,
        DLVRY_ADDRSS : this.state.nallow.DLVRY_ADDRSS,
        ORDR_NUM : this.state.nallow.ORDR_NUM,
        CSTMR_CD : this.state.nallow.CSTMR_CD,
        RGSTRTN_USR_CD : this.state.nallow.RGSTRTN_USR_CD,
        STTS_KBN : this.state.nallow.STTS_KBN,
      }
      try {
        const nallowList = await axios.post('https://nasta-csvmngmnt.com/getSearchAs', param);
        if(nallowList.data == 'NG'){
          alert("処理に失敗しました。\n管理者に連絡して下さい。");
        }else{
          commit('output', nallowList.data);
          commit('srchitm', param);
          List.computed.Installation();
          return;
        }
      }catch {
        alert('エラーが発生しました。\n管理者へお問合せ下さい。');
        return;
      }
    },
    async defaultdata({ commit },{USR_ID}) {
      try {
        var usr = {
          USR_ID: USR_ID
        }
        const nallowList = await axios.post('https://nasta-csvmngmnt.com/getAsMpgs',usr);
        if(nallowList.data == 'NG'){
          alert("処理に失敗しました。\n管理者に連絡して下さい。");
        }else{
          for(var i = 0 ; i < nallowList.data.length ; i++){
            if(nallowList.data[i].SHPPNG_SCDLE_DT == '0'){
              nallowList.data[i].SHPPNG_SCDLE_DT = '';
            }
          }
          commit('output', nallowList.data);
          List.computed.Installation();
          List.computed.SttskbnCount();
          return;
        }
      }catch{
        alert('エラーが発生しました。\n管理者へお問合せ下さい。');
        return;
      }
    },
    async clsfcton({ commit }) {
      try {
        const clsfcton = await axios.post('https://nasta-csvmngmnt.com/getClsfcton');
        const cstmr = await axios.post('https://nasta-csvmngmnt.com/getCstmr');
        if(clsfcton.data == 'NG' || cstmr.data == 'NG'){
          alert("処理に失敗しました。\n管理者に連絡して下さい。");
        }else{
          for(var i = 0; i < clsfcton.data.length; i++){
            if(clsfcton.data[i].IDNTFCTON_CD == 'CSVKB'){
              commit('CSVKB', clsfcton.data[i]);
            }
            if(clsfcton.data[i].IDNTFCTON_CD == 'PTKB'){
              commit('PTKB', clsfcton.data[i]);
            }
            if(clsfcton.data[i].IDNTFCTON_CD == 'RGKB'){
              commit('RGKB', clsfcton.data[i]);
            }
            if(clsfcton.data[i].IDNTFCTON_CD == 'STSKB'){
              commit('STSKB', clsfcton.data[i]);
            }
          }
          commit('CSTMR',cstmr.data);
          Narrow.computed.Csvkb();
          Narrow.computed.Ptkb();
          Narrow.computed.Rgkb();
          Narrow.computed.Stskb();
          Narrow.computed.Cstmr();
          return;
        }
      } catch{
        alert('エラーが発生しました。\n管理者へお問合せ下さい。');
        return;
      }
    },
    async usermst({ commit }) {
      try {
        const users = await axios.post('https://nasta-csvmngmnt.com/getUserMst');
        if(users.data == 'NG'){
          alert("処理に失敗しました。\n管理者に連絡して下さい。");
        }else{
          commit('USERS', users.data);
          Narrow.computed.PREF();
          return;
        }
      }catch{
        alert('エラーが発生しました。\n管理者へお問合せ下さい。');
        return;
      }
    },    
  },

}
