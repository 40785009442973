function parseDate(date) {
  if (!date) return null
  if(date.length == 1){
    date.padStart(10, '0000/00/00');
    return null;
  }if(date.length == 2){
    date.padStart(10, '0000/00/0');
    return null;
  }if(date.length == 3){
    date.padStart(10, '0000/00/');
    return null;
  }if(date.length == 4){
    date.padStart(10, '0000/00');
    return null;
  }if(date.length == 5){
    date.padStart(10, '0000/0');
    return null;
  }if(date.length == 6){
    date.padStart(10, '0000/');
    return null;
  }if(date.length == 7){
    date.padStart(10, '0000');
    return null;
  }if(date.length == 8){
    date.padStart(10, '000');
    return null;
  }if(date.length == 9){
    date.padStart(10, '00');
    return null;
  }if(date.length == 10){
    date.padStart(10, '0');
    if(date.substr(4,1) == '-' && date.substr(7,1) == '-' ){
      const [year ,month, day] = date.split('-');
      return `${year}-${month}-${day}`  
    }else if(date.substr(4,1) == '/' && date.substr(7,1) == '/'){
      const [year ,month, day] = date.split('/');
      return `${year}-${month}-${day}`  
    }else{
      if(date.indexOf(/^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/) == -1){
        return null;
      }
      const year = date.substr(0,4);
      const month = date.substr(5,2);
      const day = date.substr(7,2);
      return `${year}-${month}-${day}`
    }
  }
}

export default {
  parseDate,
}