<template>
<v-main style="min-height: 100%;">
<mstMntnnc />
 <Footer class="footer"/>
 </v-main>
</template>
<script>
import mstMntnnc from '@/components/mstMntnnc.vue';
import Footer from "@/components/footer.vue";

export default {
  name: "imports",
  components: {
    mstMntnnc,
    Footer,
  },
};
</script>