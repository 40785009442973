import axios from "axios";
import nowdate from './date.js';
async function inserts(shapingData,initiaLizeData,Hold,usrCd){
  try{
    const managementNum = [];
    const products = [];
    const customerProductCodes = [];
    const productNames = [];
    const orderNumubers = [];
    const unitPrices = [];
    const itemNo = [];
    const deliveryShippingNo = [];
    const customerCode = [];
    const orderKbn = [];
    const deliveryTime = [];
    const prefectures = [];
    const shippingScheduleDate = [];
    const shippingWarehouses = [];
    const shippingKbn = [];
    const customerOrderNo = [];
    const shippingExpensesKbn = [];
    const dateArrivalDesignation = [];
    const timeArrivalDesignation = [];
    const shippingCompany = [];
    const requestName = [];
    const invoiceContents1 = [];
    const viaName = [];
    const deliveryDistnationName1 = [];
    const deliveryDistnationName2 = [];
    const postCode = [];
    const deliveryAddress1 = [];
    const deliveryAddress2 = [];
    const scen = [];
    const phoneCode = [];
    const invoiceFaxRequest = [];
    const invoiceFaxPresence = [];
    const mapPresence = [];
    const remarks = [];
    const remarks2 = [];
    const holds = [];
    var lstData = [];
    var deliveryLeadTimeData = [];
    var leadTime = [];
    var empty = "";
    const MngmntNumResult = await axios.post("https://nasta-csvmngmnt.com/getMngmntNum");
    for(var i = 0; i < shapingData.length; i++){
      var s = shapingData[i][0].REMARKS2_1.replace(/\n/g,"\r\n");
      shapingData[i][0].REMARKS2_1 = s;
    }
    for(var j = 0; j < shapingData.length;j++){
      for(var k = 0; k < shapingData[j].length;k++){
        products.push(shapingData[j][k].PRDCT_CD);
        productNames.push(shapingData[j][k].PRDCT_NM);
        customerProductCodes.push(shapingData[j][k].CSTMR_PRDCT_CD);
        orderNumubers.push(shapingData[j][k].QTY);
        unitPrices.push(shapingData[j][k].UNIT_PRC);
        managementNum.push(Number(MngmntNumResult.data[0].MNGMNT_NUM)+(j+1));
        deliveryShippingNo.push(stringCut(shapingData[j][0].CSTMR_ORDR_NO + nowdate.dates()));
        customerCode.push(shapingData[j][0].CSTMR_CD);
        orderKbn.push(shapingData[j][0].ORDR_KBN);
        deliveryTime.push(shapingData[j][0].DT_ARVL_DAGNTN);
        prefectures.push(shapingData[j][0].PREFECTURES);
        if(shapingData[j][0].SHPPNG_SCDLE_DT == ''){
          shippingScheduleDate.push('0');  
        }else{
          shippingScheduleDate.push(shapingData[j][0].SHPPNG_SCDLE_DT);
        }
        shippingWarehouses.push(shapingData[j][0].SHPPNG_WREHS);
        shippingKbn.push(shapingData[j][0].SHPPNG_KBN);
        customerOrderNo.push(shapingData[j][0].CSTMR_ORDR_NO);
        shippingExpensesKbn.push(shapingData[j][0].SHPPNG_EXPNS_KBN);
        dateArrivalDesignation.push(shapingData[j][0].DT_ARVL_DAGNTN);
        timeArrivalDesignation.push(shapingData[j][0].TM_ARVL_DAGNTN);
        shippingCompany.push(shapingData[j][0].SHPPNG_CMPNY);
        requestName.push(shapingData[j][0].RQEST_NM);
        invoiceContents1.push(shapingData[j][0].INVICE_CNTNTS_1);
        viaName.push(shapingData[j][0].VIA_NM);
        deliveryDistnationName1.push(shapingData[j][0].DLVRY_DSTNTON_NM_1);
        deliveryDistnationName2.push(shapingData[j][0].DLVRY_DSTNTON_NM_2);
        postCode.push(shapingData[j][0].PST_CD);
        deliveryAddress1.push(shapingData[j][0].DLVRY_ADDRSS_1);
        deliveryAddress2.push(shapingData[j][0].DLVRY_ADDRSS_2);
        scen.push(shapingData[j][0].SCEN);
        phoneCode.push(shapingData[j][0].PHNE_CD);
        invoiceFaxRequest.push(shapingData[j][0].INVICE_FAX_RQEST);
        invoiceFaxPresence.push(shapingData[j][0].INVICE_FAX_PRSNC);
        mapPresence.push(shapingData[j][0].MAP_PRSNC);
        remarks.push(shapingData[j][0].REMARKS);
        remarks2.push(shapingData[j][0].REMARKS2_1);
        if(Hold[j] == true){
          holds.push(1);
        }else{
          holds.push(0);              
        }
        itemNo.push((k + 1)*10);
      }
    }
    const DlvryIdtmResult = await axios.post("https://nasta-csvmngmnt.com/getDlvryIdtm",{
      PREFECTURES: prefectures
    });
    if(DlvryIdtmResult.data.length > 0){
      deliveryLeadTimeData.push(DlvryIdtmResult.data);
    }
    for(var l = 0; l < initiaLizeData.length; l++){
      if(DlvryIdtmResult.data.length > 0){
        for(var m = 0; m < DlvryIdtmResult.data.length; m++){
          if(prefectures[l] == DlvryIdtmResult.data[m].PRFCTR_NM){
            leadTime.push(DlvryIdtmResult.data[m].LD_TM);
          }else if(prefectures[l] == empty){
            if(m == 0){
              leadTime.push(empty);
            }
          }
        }
      }else{
          leadTime.push(empty);
      }
      lstData.push([
        {
          MNGMNT_NUM : managementNum[l],
          DLVRY_SLP_NO :  deliveryShippingNo[l],
          ITM_NO : itemNo[l],
          CSTMR_CD : customerCode[l],
          ORDR_KBN : 1,
          DLVRY_TM : deliveryTime[l],
          SHPPNG_SCDLE_TRN : leadTime[l],
          SHPPNG_SCDLE_DT : shippingScheduleDate[l],
          SHPPNG_WREHS : shippingWarehouses[l],
          SHPPNG_KBN : shippingKbn[l],
          CSTMR_ORDR_NO : customerOrderNo[l],
          SHPPNG_EXPNS_KBN : shippingExpensesKbn[l],
          DT_ARVL_DAGNTN : dateArrivalDesignation[l],
          TM_ARVL_DAGNTN : timeArrivalDesignation[l],
          SHPPNG_CMPNY : shippingCompany[l],
          RQEST_NM : requestName[l],
          INVICE_CNTNTS_1 : invoiceContents1[l],
          VIA_NM : viaName[l],
          DLVRY_DSTNTON_NM_1 : deliveryDistnationName1[l],
          DLVRY_DSTNTON_NM_2 : deliveryDistnationName2[l],
          PST_CD : postCode[l],
          PREFECTURES: prefectures[l],
          DLVRY_ADDRSS_1 : deliveryAddress1[l],
          DLVRY_ADDRSS_2 : deliveryAddress2[l],
          SCEN : scen[l],
          PHNE_CD : phoneCode[l],
          INVICE_FAX_RQEST : invoiceFaxRequest[l],
          INVICE_FAX_PRSNC : invoiceFaxPresence[l],
          MAP_PRSNC : mapPresence[l],
          PRDCT_CD : products[l],
          PRDCT_NM : productNames[l],
          CSTMR_PRDCT_CD : customerProductCodes[l],
          QTY : orderNumubers[l],
          UNIT_PRC : unitPrices[l],
          REMARKS : remarks[l],
          REMARKS2 : remarks2[l],
          HOLD : holds[l],
          DWNLD_FLG : "0",
          CSV_OUTPT_FLG : "0",
          DEL_FLG : "0",
          USR_CD : usrCd
        }
      ]);
    }
    
    
    const InsertAsMaps = await axios.post("https://nasta-csvmngmnt.com/InsertAsMaps",{lstData});
    if(InsertAsMaps.data != "ERROR"){
      return true
    }else{
      return false
    }
  }catch{
    return false
  }
}

function stringCut(str) {
  var string = '';
  string = str.substr(0,25);
  return string
}

export default {
  inserts,
}