<template>
<v-main style="min-height: 100%;">
 <menuBar />
<pssChng />
 <Footer class="footer"/>
 </v-main>
</template>
<script>
import menuBar from "@/components/menuBar.vue";
import Footer from "@/components/footer.vue";
import pssChng from '@/components/pssChng.vue';

export default {
  name: "imports",
  components: {
    menuBar,
    Footer,
    pssChng
  },
};
</script>