<template>
  <v-container style="width: 1200px;" fluid>
    <v-dialog v-if="resultDialog == true" v-model="resultDialog" persistent max-width="400">
      <v-card>
        <v-card-title v-if="compDialog == true" class="text-h5" style="background-color:#43A047"></v-card-title>
        <v-card-title v-if="errDialog == true" class="text-h5" style="background-color:#FF5252"></v-card-title>
        <v-card-title v-if="compDialog == true">パスワードの変更が完了しました。</v-card-title>
        <v-card-title v-if="errDialog == true">パスワードの変更が出来ませんでした。<br>管理者に連絡してください。</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="router">
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>  
    <v-form>
      <p style="font-size:1.25rem;">英数混合8文字以上でパスワードを入力してください。</p>
      <v-card elevation="2">
        <v-row style="margin: auto; padding: 50px 0 20px 0;" justify="center">
          <v-col lg="11" md="11">
            <div class="inline clearfix">
              <div class="text-center">新しいパスワード<span>*</span></div>
              <v-text-field autocomplete="off" outlined :rules="nameRules" :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword1 ? 'text' : 'password'" @click:append="showPassword1 = !showPassword1" @keyup.enter="passchenged" v-model="psswd1"></v-text-field>
            </div>
            <div class="inline clearfix">
              <div class="text-center">新しいパスワード（再入力）<span>*</span></div>
              <v-text-field autocomplete="off" outlined :rules="nameRules" :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword2 ? 'text' : 'password'" @click:append="showPassword2 = !showPassword2" @keyup.enter="passchenged" v-model="psswd2"></v-text-field>
            </div>              
            <div class="inline clearfix">
              <div class="btns">
                <v-btn :disabled="!ValueCheck" height="50" class="sub" color="primary" elevation="2"  @click="chenge">パスワードを変更する</v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
    <div class="text-center">
      <v-snackbar color="red darken-2" v-model="error" :multi-line="multiLine">
        <template v-slot:action="{ attrs }">{{text}}
          <v-btn color="#FFFFFF" text v-bind="attrs" @click="error = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>    
  </v-container>
</template>

<script>
import passUpdate from "./javascript/passUpdate.js";
export default {
  name: "nallows",
  data: () => ({
    showPassword1: false,
    showPassword2: false,
    psswd1: null,
    psswd2: null,
    usrId: null,
    error: null,
    compDialog: false,
    resultDialog: false,
    errDialog: false,
    multiLine: true,
    text: `入力されたパスワードが一致しません。`,        
    nameRules: [
      v => !!v || '新しいパスワードを入力して下さい。',
      v => /^(?=.*?[a-zA-Z])(?=.*?\d)[a-zA-Z\d]{8,}$/.test(v) || '8文字以上の半角英数字で入力してください。',
    ],
  }),
  computed: {
    ValueCheck() {
      return this.psswd1 && this.psswd2 && this.psswd1.length >=8 && this.psswd2.length >=8;
    }
  },
  methods: {
    ResetFields() {
    this.error = false;
    },
    router() {
      this.compDialog = false;
      this.$router.push("/orders");
    },
    chenge() {
      passUpdate.passUpdate(this.usrId,this.psswd1,this.psswd2).then(result => {
        if(!result){
          this.error = true;
        }else if(result == "ERROR"){
          this.resultDialog = true;
          this.errDialog = true;
        }else{
          this.resultDialog = true;
          this.compDialog = true;
        }
      });
    }
  },
   async mounted() {
     this.usrId = this.$store.state.login.userId;
   },
};
</script>

<style scoped>
.clearfix::after {
  content: " ";
  display: block;
  clear: both;
}
.text-center {
  width: 250px;
  font-size: 16px;
  line-height: 55px;
  height: 55px;
}
.inline{
  display: flex;
  margin: 10px;
}
.btns{
  margin: auto;
  width: 100%;
  float: right;
}
.sub{
  float: right;
}
span{
  color: red;
}
</style>

