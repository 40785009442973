<template>
  <v-container style="height: auto;">
  <span style="font-size:20px;background: linear-gradient(transparent 70%, #ffff7f 0%);">保留 {{SttskbnCount}} 件</span>
    <v-row justify="center">
      <v-col cols="12">
        <div>
          <v-data-table :headers="headers"
            :items="Installation"
            v-model="selected"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            fixed-header
            height="400px"
            show-select
            loading-text="読込中"
            no-data-text="データがありません。"
            item-key="MNGMNT_NUM"
            class="elevation-1"
            @page-count="pageCount = $event"
            @click:row="
            (data)=> {
            $router.push({path: `/orders/${data.MNGMNT_NUM}`});
            }"
          >
            <template v-slot:item.STTS_KBN="{ item }">
            <div class="axax">
              <span class="onhold" v-if="item.STTS_KBN == '1'">保留</span>
              <span class="onord" v-else>受注</span>
            </div>
            </template>
            <template v-slot:item.DWNLD_FLG ="{ item }">
              <v-icon v-if="item.DWNLD_FLG == '1'" color="#757575" v-model="item.DWNLD_FLG">mdi-check-circle</v-icon>
            </template>
            <template v-slot:item.CSV_OUTPT_FLG="{ item }">
              <v-icon v-if="item.CSV_OUTPT_FLG == '1'" color="#757575" v-model="item.CSV_OUTPT_FLG">mdi-check-circle</v-icon>
            </template>
          </v-data-table>
          <div class="btns clearfix">
            <div class="left-btns">
              <v-dialog v-model="asDialog" persistent max-width="500">
                <template v-slot:activator="{ on, attrs }">
                <v-btn class="white--text btn" color="#00ACF9" v-bind="attrs" v-on="on" :disabled="!ValueCheck"  @click="frameNumCheck">AS出力</v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5" style="background-color:#00ACF9;color:#ffffff">CSV出力</v-card-title>
                  <v-card-text class="pa-5" style="color: rgba(0,0,0,.8);font-size: 16px;">出力して宜しいですか？</v-card-text>
                  <v-data-table v-if="frameFlg" :headers="frameheaders" :items="frameResult" fixed-header hide-default-footer loading-text="読込中" no-data-text="データがありません。"></v-data-table>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="asDialog = false">
                    cancel
                    </v-btn>
                    <v-btn color="green darken-1" text @click="downloadCSV">
                    ok
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-btn class="white--text btn" color="#00ACF9" :disabled="!ValueCheck" @click="downloadPDF()">注文書印刷</v-btn>
            </div>
         
          </div>
          <div class="pagination"> 
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

import StorenallowList from "../store/modules/nallow.js";
import date from './javascript/date.js';
import csv from './javascript/createCsv.js';
import pdf from './javascript/createPdf.js';
export default {
  data: ()=> {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      csvResult: [],
      frameResult: [],
      mngmntNumCount: [],
      dialog: false,
      asDialog: false,
      frameFlg: false,
      selected:[],
      headers: [
        { text: '出荷予定日', width:'9%', align:'center', sortable: true, value: 'SHPPNG_SCDLE_DT'},
        { text: '着日予定', width:'9%', align:'center', value: 'DLVRY_TM'},
        { text: '管理番号', width:'8%', align:'center', value: 'MNGMNT_NUM'},
        { text: '受注番号', width:'6%', align:'center', value: 'ORDR_NUM'},
        { text: '客先注文No', width:'8%', align:'center', value: 'CSTMR_ORDR_NO'},
        { text: '物件名', width:'8%', align:'center',value: 'SCEN'},
        { text: '得意先名', width:'8%', align:'center', value: 'CSTMR_NM'},
        { text: '納品先名', width:'8%', align:'center', value: 'DLVRY_DSTNTON_NM_1'},
        { text: '都道府県', width:'5%', align:'center', value: 'PREFECTURES'},
        { text: 'ステータス', width:'6%',align:'center', value: 'STTS_KBN'},
        { text: 'CSV', width:'3%', align:'center', value: 'CSV_OUTPT_FLG'},
        { text: '印刷', width:'4%', align:'center', value: 'DWNLD_FLG'},
        { text: '登録日', width:'10%', align:'center', value: 'RGSTRTN_DT'},
        { text: '最終更新者', width:'6%', align:'center', value: 'USR_NM'},
      ],
      frameheaders:[
          {text: '出荷予定日', align:'center',sortable: true, value: 'SHPPNG_SCDLE_DT'},
          {text: '標準化区分', align:'center', value: 'CLSFCTON_NM'},
          {text: '使用枠数', align:'center', value: 'MTH_QTY'},
      ]
    }
  },
  computed: {
    Installation() {
      return StorenallowList.state.messages;
    },
    SttskbnCount() {
      var messageslength = 0;
      for(var i = 0;i < StorenallowList.state.messages.length; i++){
        if(StorenallowList.state.messages[i].STTS_KBN == '1'){
          ++ messageslength;
        }
      }
      return messageslength;
    },
    ValueCheck() {
      return this.selected.length;
    },
  },
  methods: {
    async frameNumCheck() {
      this.frameFlg = false;
      this.frameResult = [];
      for(var i = 0; i < this.selected.length; i++){
        this.mngmntNumCount.push(this.selected[i].MNGMNT_NUM);
      }
      const frameResult = await axios.post('https://nasta-csvmngmnt.com/getFrameGroupCount',{MNGMNT_NUM : this.mngmntNumCount});
      for(var b = 0; b < frameResult.data.length; b++){
        this.frameResult.push(frameResult.data[b]);  
      }
      if(this.frameResult.length > 0){
        this.frameFlg = true;
      }
      this.mngmntNumCount = [];
    },    
    async downloadCSV() {
      this.csvResult = [];
      for(var i = 0; i < this.selected.length; i++){
        this.mngmntNumCount.push(this.selected[i].MNGMNT_NUM);
        if(this.selected[i].STTS_KBN == '1'){
          alert("保留の管理番号が選択されています。保留以外を選択してください。")
          return
        }
      }
      const csvResult = await axios.post('https://nasta-csvmngmnt.com/getCsvResults',{MNGMNT_NUM : this.mngmntNumCount});
      this.csvResult.push(csvResult.data);
      for(var j = 0; j <= this.csvResult[0].length-1; j++){
        this.csvResult[0][j].SHPPNG_SCDLE_DT = this.csvResult[0][j].SHPPNG_SCDLE_DT.replace(/-/g,"");
        this.csvResult[0][j].DLVRY_TM = this.csvResult[0][j].DLVRY_TM.replace(/-/g,"");
        this.csvResult[0][j].DT_ARVL_DAGNTN = this.csvResult[0][j].DT_ARVL_DAGNTN.replace(/-/g,"").substr(4);
        if(this.csvResult[0][j].PST_CD.indexOf("-") != "-1"){
          this.csvResult[0][j].PST_CD = this.csvResult[0][j].PST_CD.replace(/-/g,"");
        }
        
        if(this.csvResult[0][j].INVICE_FAX_RQEST == '0'){
          this.csvResult[0][j].INVICE_FAX_RQEST = ''
        }
        if(this.csvResult[0][j].INVICE_FAX_PRSNC == '0'){
          this.csvResult[0][j].INVICE_FAX_PRSNC = ''
        }
        if(this.csvResult[0][j].MAP_PRSNC == '0'){
          this.csvResult[0][j].MAP_PRSNC = ''
        }
        if(this.csvResult[0][j].PST_CD == '0'){
          this.csvResult[0][j].PST_CD = ''
        }
        if(this.csvResult[0][j].SHPPNG_SCDLE_TRN == ''){
          this.csvResult[0][j].SHPPNG_SCDLE_TRN = '0'
        }
      }
      csv.CreateCsv(this.csvResult).click();
      this.mngmntNumCount = [];
      this.selected = [];
      this.asDialog = false;
      this.$store.dispatch("nallow/dwnldnallow");
    },
    async downloadPDF() {
      for(var i = 0; i < this.selected.length; i++){
        this.mngmntNumCount.push(this.selected[i].MNGMNT_NUM);
        if(this.selected[i].STTS_KBN == '1'){
          alert("保留の管理番号が選択されています。保留以外を選択してください。")
          return
        }        
      }
      const asresult = await axios.post('https://nasta-csvmngmnt.com/getPdfResults',{MNGMNT_NUM : this.mngmntNumCount});
      const result = asresult.data.filter((element, index, self) => 
        self.findIndex(e => e.MNGMNT_NUM === element.MNGMNT_NUM) === index
      );
      pdfMake.createPdf(pdf.CreatePdf(result,asresult.data)).download("OrderInstruction"+date.dates()+".pdf");// eslint-disable-line
      this.mngmntNumCount = [];
      this.selected = [];
      this.$store.dispatch("nallow/dwnldnallow");
    },
  },
}
</script>

<style>
.onhold {
  color: red;
  font-weight: bold;
}

.text-start {
    font-size: 13px !important;
}
.clearfix::after {
  content: " ";
  display: block;
  clear: both;
}
.btn {
  margin: 10px 20px 0;
}
.elevation-1 {
  cursor: pointer;
}
.text-h5{
  font-size:1rem;
  color: #ffffff;
}

.left-btns{
  float: left;
  left: 0;
}
.right-btns{
  float: right;
  right: 0;
}
.pagination{
  margin-bottom: 60px ;
}
</style>