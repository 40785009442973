async function prdctNumget(CstmrMngmntNum,CstmrtName){
  try{
    var cstmr_prdct_num = null;
    for(var i = 0; i < CstmrMngmntNum.length; i++){
      if(CstmrMngmntNum[i].CSTMR_MNGMNT_NUM == CstmrtName){
        cstmr_prdct_num = CstmrMngmntNum[i].CSTMR_PRDCT_NUM
      }
    }
    return cstmr_prdct_num
    }catch{
      alert("処理に失敗しました管理者に連絡して下さい。");
    }
}
export default {
  prdctNumget,
}