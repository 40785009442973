
import axios from 'axios'
import FileInput from "../../components/fileInput.vue"
import cleatItem from "../../components/cleatItem.vue"
import UpdateItem from "../../components/item.vue"
export default {
  namespaced: true,
  state: {
    CstmrMngmntNum: [],
    CstmrtName: null,
    MaxModel: null,
    ShapingData: [],
    display: false,
    hskbs: [],
    uckbs: [],
    trsps: [],
    jukb2s: [],
    sokbs: [],
    umkbs: [],
    prefs: [],
    prdcts: [],
  },
  mutations: {
    output(state, CstmrMngmntNumResult) {
      state.CstmrMngmntNum = CstmrMngmntNumResult;
    },
    HSKB(state, hskbList) {
      state.hskbs.push(hskbList);
    },
    UCKB(state, uckbList) {
      state.uckbs.push(uckbList);
    },
    TRSP(state, trspList) {
      state.trsps.push(trspList);
    },
    JUKB2(state, jukb2List) {
      state.jukb2s.push(jukb2List);
    },
    SOKB(state, sokbList) {
      state.sokbs.push(sokbList);
    },
    UMKB(state, umkbList) {
      state.umkbs.push(umkbList);
    },
    PREF(state, prefList) {
      state.prefs.push(prefList);
    },
    PRDC(state, prdcList) {
      state.prdcts.push(prdcList);
    },
  }, 
  actions: {
    async CstmrMngmntNum({ commit }) {
      try {
        const CstmrMngmntNumResult = await axios.post("https://nasta-csvmngmnt.com/getCstmrMngmntNum");
        commit('output', CstmrMngmntNumResult.data);
        FileInput.computed.CstmrMngmntNum();
          return;
      } catch{
        alert('処理に失敗しました。管理者へお問合せ下さい。');
        return;
      }
    },
    async displays() {
      try {
        cleatItem.computed.displays();
          return;
      } catch{
        alert('処理に失敗しました。管理者へお問合せ下さい。');
        return;
      }
    },
    async clsfcton({ commit }) {
      try {
        const clsfcton = await axios.post('https://nasta-csvmngmnt.com/getClsfcton');
        if(clsfcton.data == 'NG'){
          alert("処理に失敗しました。\n管理者に連絡して下さい。");
        }else{
          for(var i = 0; i < clsfcton.data.length; i++){
            if(clsfcton.data[i].IDNTFCTON_CD == 'HSKB'){
              commit('HSKB', clsfcton.data[i]);
            }
            if(clsfcton.data[i].IDNTFCTON_CD == 'UCKB'){
              commit('UCKB', clsfcton.data[i]);
            }
            if(clsfcton.data[i].IDNTFCTON_CD == 'TRSP'){
              commit('TRSP', clsfcton.data[i]);
            }
            if(clsfcton.data[i].IDNTFCTON_CD == 'JUKB2'){
              commit('JUKB2', clsfcton.data[i]);
            }
            if(clsfcton.data[i].IDNTFCTON_CD == 'SOKB'){
              commit('SOKB', clsfcton.data[i]);
            }
            if(clsfcton.data[i].IDNTFCTON_CD == 'UMKB'){
              commit('UMKB', clsfcton.data[i]);
            }
          }
          cleatItem.computed.Hskb();
          cleatItem.computed.Uckb();
          cleatItem.computed.trsp();
          cleatItem.computed.jukb2();
          cleatItem.computed.SOKB();
    
          return;
        }
      } catch{
        alert('エラーが発生しました。\n管理者へお問合せ下さい。');
        return;
      }
    },
    async Prefectures({ commit }) {
      try {
        const Prefectures = await axios.post('https://nasta-csvmngmnt.com/getPrefectures');
        if(Prefectures.data == 'NG'){
          alert("処理に失敗しました。\n管理者に連絡して下さい。");
        }else{
          for(var i = 0; i < Prefectures.data.length; i++){
            commit('PREF', Prefectures.data[i]);
          }
          cleatItem.computed.PREF();
          UpdateItem.computed.PREF();
          return;
        }
      } catch{
        alert('エラーが発生しました。\n管理者へお問合せ下さい。');
        return;
      }
    },
    async Prdcts({ commit }) {
      try {
        const Prdcts = await axios.post('https://nasta-csvmngmnt.com/getPrdcts');
        if(Prdcts.data == 'NG'){
          alert("処理に失敗しました。\n管理者に連絡して下さい。");
        }else{
          for(var i = 0; i < Prdcts.data.length; i++){
            commit('PRDC', Prdcts.data[i]);
          }
          cleatItem.computed.PRDC();
          UpdateItem.computed.PRDC();
          return;
        }
      } catch{
        alert('エラーが発生しました。\n管理者へお問合せ下さい。');
        return;
      }
    },    
    async Updateclsfcton({ commit }) {
      try {
        const clsfcton = await axios.post('https://nasta-csvmngmnt.com/getClsfcton');
        if(clsfcton.data == 'NG'){
          alert("処理に失敗しました。\n管理者に連絡して下さい。");
        }else{
          for(var i = 0; i < clsfcton.data.length; i++){
            if(clsfcton.data[i].IDNTFCTON_CD == 'HSKB'){
              commit('HSKB', clsfcton.data[i]);
            }
            if(clsfcton.data[i].IDNTFCTON_CD == 'UCKB'){
              commit('UCKB', clsfcton.data[i]);
            }
            if(clsfcton.data[i].IDNTFCTON_CD == 'TRSP'){
              commit('TRSP', clsfcton.data[i]);
            }
            if(clsfcton.data[i].IDNTFCTON_CD == 'JUKB2'){
              commit('JUKB2', clsfcton.data[i]);
            }
            if(clsfcton.data[i].IDNTFCTON_CD == 'SOKB'){
              commit('SOKB', clsfcton.data[i]);
            }
            if(clsfcton.data[i].IDNTFCTON_CD == 'UMKB'){
              commit('UMKB', clsfcton.data[i]);
            }
          }
          UpdateItem.computed.Hskb();
          UpdateItem.computed.Uckb();
          UpdateItem.computed.trsp();
          UpdateItem.computed.jukb2();
          UpdateItem.computed.SOKB();          
          return;
        }
      } catch{
        alert('エラーが発生しました。\n管理者へお問合せ下さい。');
        return;
      }
    },        
  },
}
