<template>
  <v-container class="v-container">
   <v-row>
      <v-col>
        <v-card height="120px"  elevation="2"  class="v-container">
          <div class="csv_input">
            <v-file-input class="file_input" type="file" accept=".csv" v-model="fileName" counter clearable @change="datInput" label="CSVファイル"></v-file-input>
            <v-card-actions>
              <v-select height="15px" item-text="CSTMR_NM" label="客先名選択" item-value="CSTMR_MNGMNT_NUM" v-model="cstmrtName" :items="CstmrMngmntNum" class="cumpany-styled-input"></v-select>
              <v-btn :disabled="!InputCheck" class="csvbtn" elevation="9" color="#00ACF9"  @click="uploads">アップロード</v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import nowdate from './javascript/date.js';
import CleateItem from "../store/modules/cleateItem.js";
export default {
  data: () => ({
    cstmrtName: null,
    fileName: null,
    initiaLizeData:[],
    model: null,
    maxModel: null,
    shapingData: [],
    tmpData: [],
    workers: [],
  }),
  methods: {
    datInput(inputdatas) {
      if(inputdatas != null){
        const file = inputdatas;
        const reader = new FileReader();
        reader.readAsText(file,"Shift_JIS");
        reader.onload = () => {
          let lines = reader.result.split("\n");
          lines.shift();
          lines.pop();
          let linesArr = [];
          for (let i = 0; i < lines.length; i++) {
            linesArr[i] = lines[i].split(",");
          }
          this.workers = linesArr;
        };
      }
    },
      uploads() {
      try{
        var selectnum = [];
        for(var s = 0; s < this.CstmrMngmntNum.length; s++){
          if(this.cstmrtName == this.CstmrMngmntNum[s].CSTMR_MNGMNT_NUM){
          selectnum = this.CstmrMngmntNum[s];
          }
        }
        this.AllClaer();
        for(var i = 0; i < this.workers.length; i++){          
          this.initiaLizeData.push(
            {
              CSTMR_CD : selectnum.CSTMR_CD,
              DLVRY_SLP_NO : this.workers[i][Number(selectnum.DLVRY_SLP_NO)] + nowdate.dates(),
              ITM_NM : this.workers[i][Number(selectnum.ITM_NO)],
              SHPPNG_EXPNS_KBN : this.workers[i][Number(selectnum.SHPPNG_EXPNS_KBN)],
              SHPPNG_KBN : this.workers[i][Number(selectnum.SHPPNG_KBN)],
              RQEST_NM : this.workers[i][Number(selectnum.RQEST_NM)],
              PST_CD : this.workers[i][Number(selectnum.PST_CD)],
              PHNE_CD : this.workers[i][Number(selectnum.PHNE_CD)],
              INVICE_CNTNTS_1 : this.workers[i][Number(selectnum.INVICE_CNTNTS_1)],
              DT_ARVL_DAGNTN : this.workers[i][Number(selectnum.DT_ARVL_DAGNTN)],
              TM_ARVL_DAGNTN : this.workers[i][Number(selectnum.TM_ARVL_DAGNTN)],
              SHPPNG_CMPNY : this.workers[i][Number(selectnum.SHPPNG_CMPNY)],
              CSTMR_ORDR_NO : this.workers[i][Number(selectnum.CSTMR_ORDR_NO)],
              ORDR_KBN : this.workers[i][Number(selectnum.ORDR_KBN)],
              DLVRY_TM : this.workers[i][Number(selectnum.DLVRY_TM)],
              SHPPNG_SCDLE_TRN : this.workers[i][Number(selectnum.SHPPNG_SCDLE_TRN)],
              VIA_NM : this.workers[i][Number(selectnum.VIA_NM)],
              DLVRY_DSTNTON_NM_1 : this.workers[i][Number(selectnum.DLVRY_DSTNTON_NM_1)],
              DLVRY_DSTNTON_NM_2 : this.workers[i][Number(selectnum.DLVRY_DSTNTON_NM_2)],
              DLVRY_ADDRSS_1 : this.workers[i][Number(selectnum.DLVRY_ADDRSS_1)],
              DLVRY_ADDRSS_2 : this.workers[i][Number(selectnum.DLVRY_ADDRSS_2)],
              SCEN : this.workers[i][Number(selectnum.SCEN)],
              SHPPNG_WREHS : this.workers[i][Number(selectnum.SHPPNG_WREHS)],
              SHPPNG_SCDLE_DT : this.workers[i][Number(selectnum.SHPPNG_SCDLE_DT)],
              INVICE_FAX_RQEST : this.workers[i][Number(selectnum.INVICE_FAX_RQEST)],
              INVICE_FAX_PRSNC : this.workers[i][Number(selectnum.INVICE_FAX_PRSNC)],
              MAP_PRSNC : this.workers[i][Number(selectnum.MAP_PRSNC)],
              PRDCT_CD : this.workers[i][Number(selectnum.PRDCT_CD)],
              PRDCT_NM : this.workers[i][Number(selectnum.PRDCT_NM)],
              QTY : this.workers[i][Number(selectnum.QTY)],
              SHPPNG_UNIT_CD : this.workers[i][Number(selectnum.SHPPNG_UNIT_CD)],
              UNIT_PRC : this.workers[i][Number(selectnum.UNIT_PRC)],
              REMARKS  : this.workers[i][Number(selectnum.REMARKS)]
            }
          );
        }
        const result = this.initiaLizeData.filter((element, index, self) => 
          self.findIndex(e => e.ITM_NM === element.ITM_NM) === index
        );
        for(var d = 0; d < result.length; d++){
          for(var j = 0;j < this.initiaLizeData.length; j++){
            if(result[d].ITM_NM === this.initiaLizeData[j].ITM_NM){
              this.tmpData.push(
                this.initiaLizeData[j],
                );
            }else {
              continue;
            }
          }
          this.shapingData.push(...[
            this.tmpData,
          ]);
          this.tmpData = [];
        }
        this.maxModel = this.shapingData.length;
        }catch{
          alert("処理に失敗しました管理者に連絡して下さい。");
        }
        this.display = true;
        this.$store.state.shapingData = this.shapingData;
        this.$store.state.display = this.display;
        this.$store.maxModel = this.maxModel;
        console.log(this.$store.state.shapingData);
        console.log(this.$store.state.display);
        this.$store.dispatch("cleateItem/displays");
    },
    AllClaer(){
      this.shapingData = [];
      this.initiaLizeData = [];
    },
  },
  computed: {
    CstmrMngmntNum() {
      return CleateItem.state.CstmrMngmntNum;
    },
    InputCheck() {
      return this.cstmrtName && this.fileName;
    },
    
  },
  async mounted() {
      this.$store.dispatch("cleateItem/CstmrMngmntNum");
    },
}
</script>
<style>
.csv_input{
  width: 800px;
  padding-top: 25px;
  margin: auto auto;
  text-align: center;
}
.file_input {
  width: 400px;
  margin: auto auto;
  float:left;
}
.csvbtn{
  float:right;
  width: 150px;
  margin-top: 10px;
  margin-left: 50px;
  color: #ffffff;
}
.cumpany-styled-input{
  height: 35px;
font-size: 14px;
margin: 7px 0 0 20px;
}
</style>