<template>
  <v-app-bar app color="primary" dark>
    <span style="font-size:20px;">
      {{kinds}}
    </span>
    <v-spacer></v-spacer>
    <span style="margin: 5px 30px 0 0;" v-if="user">USER：{{ usr }}</span>
    <v-btn v-if="mstmnt == 1 && this.transitionFlg" text class="identification_btn" style="font-size:16px;" color="white" @click="mstmntnnc">マスタメンテナンス</v-btn>
    <v-btn v-if="pssChng" text class="identification_btn" style="font-size:16px;" color="white" @click="paschng">パスワード変更</v-btn>
    <v-icon v-if="logouticon" style="margin-right: 50px;" @click="logout">mdi-home-export-outline</v-icon>
  </v-app-bar>
</template>

<script>
export default {
  name: "menuBar",
  data: ()=> {
    return{
      kinds: null,
      user: true,
      logouticon: true,
      pssChng: false,
      transitionFlg: false,
    }
  },
  mounted() {
    if(location.pathname == "/orders"){
      this.kinds = "受注情報";
      this.pssChng = true;
      this.transitionFlg = true;
    }else if(location.pathname == "/orders/import"){
      this.kinds = "受注情報登録";
      this.transitionFlg = false;
    }else if(location.pathname == "/orders/pssChng"){
      this.kinds = "パスワード変更";
      this.pssChng = false;
      this.transitionFlg = false;
    }else if(location.pathname == "/login"){
      this.kinds = "ログイン";
      this.user = false;
      this.logouticon = false;
      this.pssChng = false;
      this.transitionFlg = false;
    }else{
      this.kinds = "受注情報変更";
    }
  },
  computed: {
    usr() {
      return this.$store.state.login.userNm
    },
    mstmnt() {
      return this.$store.state.login.mstmnt
    },
  },
  methods: {
    paschng() {
      this.$router.push("/orders/pssChng");
    },
    mstmntnnc(){
      this.$router.push("/orders/mstMntnnc");
    },
    logout() {
      this.$store.dispatch("login/logput");
      this.$router.push("/login");
      this.$store.state.nallow.RGSTRTN_USR_CD = null;
    },
  }, 
};
</script>
<style scoped>
.identification_btn {
  margin: 5px 20px 0 0;
}

</style>
