import Vue from 'vue';
import Vuex from 'vuex';
import { login,nallow,cleateItem,clsfcton } from './modules';
//import  from './modules/login.js';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    login,
    nallow,
    cleateItem,
    clsfcton,
  },
  plugins: [createPersistedState({ 
      key: 'acscs',
      paths: ['login.userNm','login.userId','login.isLogin','login.mstmnt'],
      storage: window.sessionStorage
    }
  )]
});
