import axios from 'axios'
async function passUpdate(usr_id,pass_1,pass_2){
  try{
    if(passCheck(pass_1,pass_2)){
      var param = {
        USR_ID: usr_id,
        NEW_PASS: pass_2,
      }
      const result = await axios.post('https://nasta-csvmngmnt.com/updatePass', param);
      return result.data
    }else{
      return false
    }
    }catch{
      alert("処理に失敗しました管理者に連絡して下さい。");
    }
}

function passCheck(pass_1,pass_2) {
  if(pass_1 == pass_2){
    return true
  }else{
    return false
  }
}

export default {
  passUpdate,
}