<template>      
  <v-main style="min-height: 100%;">
  <menuBar />
  <item />  
  <Footer />
  </v-main>
</template>

<script>
import menuBar from "@/components/menuBar.vue";
import Footer from "@/components/footer.vue";
import item from "@/components/item.vue";
export default {
  name: "Details",
  components: {
    menuBar,
    item,
    Footer
  },
};
</script>
<style>
.footer {
  position:absolute;
  width: 100%;
  background-color: #89c7de;
  color: #fff;
}
</style>
