function codeChange(cstmrs){
  try{
    var affter_cstmrs = [];
    for(var i = 0; i < cstmrs.length; i++){
      if(cstmrs[i].CSTMR_PRDCT_NUM != 0){        
        affter_cstmrs.push(cstmrs[i]);
      }
    }
    for(var j = 0; j < affter_cstmrs.length; j++){
      affter_cstmrs[j].CSTMR_PRDCT_NUM = "CSTMR" + affter_cstmrs[j].CSTMR_PRDCT_NUM + "_PRDCT_CD";
    }
    return affter_cstmrs
    }catch{
      alert("処理に失敗しました管理者に連絡して下さい。");
    }
}

export default {
  codeChange,
}