import axios from "axios";
async function select(datas){
  try{
    const result = await axios.post("https://nasta-csvmngmnt.com/getAllAsMpgs",datas);
    var messages = [];
    if(result.data != null){
       messages = result.data;
    }else{
    alert("データの取得に失敗しました。"); 
    }
    return messages
  }catch{
    alert("更新処理に失敗しました。");
  }
}


export default {
  select,

}