<template>
  <div class="my-16">
    <v-container fluid>
      <v-row justify="center">
        <v-col  lg="4" md="4">
          <v-card class="mx-auto pa-3 elevation-12 ">
            <v-card-text>
              <v-form>
                <v-text-field label="メールアドレス" prepend-icon="mdi-account" type="text" :rules="emailRules" v-model="userId"></v-text-field>
                <v-text-field autocomplete="off" id="password" label="パスワード" prepend-icon="mdi-lock" :rules="nameRules" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword" @keyup.enter="login" v-model="password"></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn :disabled="!ValueCheck" color="primary" block @click="login">Sign in</v-btn>
            </v-card-actions>
          </v-card>
          <div class="text-center">
            <v-snackbar color="red darken-2" v-model="error" :multi-line="multiLine">
              <template v-slot:action="{ attrs }">{{text}}
                <v-btn color="#FFFFFF" text v-bind="attrs" @click="error = false">
                  Close
                </v-btn>
              </template>
            </v-snackbar>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "login",
  data: () => ({
    showPassword: false,
    userId: null,
    password: null,
    isLogin: false,
    error: '',
    multiLine: true,
    text: `メールアドレスまたはパスワードが違います。`,    
    emailRules: [
      v => !!v || 'メールアドレスを入力して下さい。',
      v => /.+@.+\..+/.test(v) || 'メールアドレス形式で入力して下さい。',
    ],
    nameRules: [
      v => !!v || 'パスワードを入力して下さい。',
      v => /^([a-zA-Z0-9]{8,})$/.test(v) || '8文字以上の半角英数字で入力してください。',
    ],
  }),
  computed: {
    ValueCheck() {
      return this.userId && this.password && this.password.length >= 8;
    }
  },
  methods: {
    ResetFields() {
    this.error = false;
    },
    async login() {
      var datas = {
        userId: this.userId,
        password: this.password,
      };
      try{
        const result = await axios.post("https://nasta-csvmngmnt.com/login",datas);
        if(result.data != "NG"){
          this.$store.state.login.isLogin = true;
          this.$store.state.login.userId = result.data[0].USR_CD;
          this.$store.state.login.userNm = result.data[0].USR_NM;
          this.$store.state.login.mstmnt = result.data[0].MST_MNTNNC_FLG;
        }else{
          this.$store.state.login.isLogin = false
          this.error = true;
        }
      }catch{
        alert("処理に失敗しました。");
      }  
      if(this.$store.state.login.isLogin){
        this.$router.push("/orders");
      }
    },
  },
};
</script>
<style>
.footer {
  position:fixed;
  width: 100%;
  background-color: #89c7de;
  color: #fff;
}
</style>