<template>
  <v-card style="margin-bottom: 50px; top: 0;">
    <v-toolbar color="primary" dark flat>
      マスタメンテナンス
      <v-spacer></v-spacer>
      <v-btn text class="identification_btn" style="font-size:16px;" color="white" @click="back">受注情報一覧へ</v-btn>
      <template v-slot:extension>
        <v-tabs  v-model="tabs" align-with-title>
        <v-tabs-slider color="green"></v-tabs-slider>
          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-dialog v-if="resultdialog == true" v-model="resultdialog" persistent max-width="500">
      <v-card>
        <v-card-title v-if="compdialog == true" class="text-h5" style="background-color:#43A047">アップロード完了</v-card-title>
        <v-card-title v-if="errdialog == true" class="text-h5" style="background-color:#FF5252">処理エラー</v-card-title>
        <v-card-title v-if="prdctdatadialog == true" class="text-h6" style="background-color:#FF5252;color:white">アップロードエラー</v-card-title>
        <v-card-title v-if="compdialog == true">マスタのアップロードが完了しました。</v-card-title>
        <v-card-title v-if="prdctdatadialog == true">マスタのアップロードが出来ませんでした。<br>データの内容を確認して下さい。</v-card-title>
        <v-card-title v-if="errdialog == true">アップロードが出来ませんでした。<br>管理者に連絡してください。</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogReset">
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="center">
      <v-col md="6">
        <div class="csv_input">
          <v-card-actions>
            <v-file-input class="file_input" type="file" accept=".csv" v-model="FileName" counter clearable @change="datInput" label="CSVマスタファイル"></v-file-input>
            <v-btn :disabled="!InputCheck" class="csvbtn" elevation="9" color="#00ACF9"  @click="upload">アップロード</v-btn>
          </v-card-actions>
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <v-card justify="center">
          <v-btn class="ma-5" color="primary" @click="usrMst">表示する</v-btn>
          <v-row v-if="usrMstTable" justify="center">
            <v-col md="10">
              <v-card-title>
                ユーザーマスタ
                <v-spacer></v-spacer>
                <v-text-field v-model="usrMstSearch" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
              </v-card-title>
              <v-data-table class="ma-5" :headers="usrMst_headers" :search="usrMstSearch" :items-per-page="itemsPerPage" :items="usrmstdata[0]" loading-text="読込中" no-data-text="データがありません。"></v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <v-col md="4">
          <v-card-actions>
          <v-btn class="ma-5" color="primary" :disabled="!cstmrCheck" @click="prdctMst">表示する</v-btn>
           <v-autocomplete label="選択" item-text="CSTMR_NM" item-value="CSTMR_PRDCT_NUM" :items="codeChngResult" hide-no-data v-model="cstmr_value"></v-autocomplete>
          </v-card-actions>
          </v-col>
          <v-row v-if="prdctMstTable" justify="center">
            <v-col  md="10">
              <v-card-title>
                製品マスタ
                <v-spacer></v-spacer>
                <v-text-field v-model="prdctMstSearch" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
              </v-card-title>
              <v-data-table class="ma-5" :headers="prdctMst_headers" :search="prdctMstSearch" :items-per-page="itemsPerPage" :items="prdctMsData[0]" loading-text="読込中" no-data-text="データがありません。"></v-data-table>
            </v-col>
          </v-row>          
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card  justify="center">
          <v-btn class="ma-5" color="primary" @click="dlvryLdtmMst">表示する</v-btn>
          <v-row v-if="dlvryLdtmMstTable" justify="center">
            <v-col md="5">
              <v-card-title>
                リードタイムマスタ
                <v-spacer></v-spacer>
                <v-text-field v-model="dlvryLdtmMstSearch" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
              </v-card-title>
              <v-data-table class="ma-5" :headers="dlvryLdtmMst_headers" :search="dlvryLdtmMstSearch" :items-per-page="itemsPerPage" :items="dlvryLdtmMstData[0]" loading-text="読込中" no-data-text="データがありません。"></v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card  justify="center">
          <v-btn class="ma-5" color="primary" @click="cstmrMpgMst">表示する</v-btn>
          <v-row v-if="cstmrMpgMstTable" justify="center">
            <v-col md="25">
              <v-card-title>
                得意先マッピングマスタ
                <v-spacer></v-spacer>
                <v-text-field v-model="cstmrMpgMstSearch" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
              </v-card-title>
              <v-data-table class="ma-5" :headers="cstmrMpgMst_headers" :search="cstmrMpgMstSearch" :items-per-page="itemsPerPage" :items="cstmrMpgMstData[0]" loading-text="読込中" no-data-text="データがありません。"></v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card  justify="center">
          <v-btn class="ma-5" color="primary" @click="clsfctonMst">表示する</v-btn>
          <v-row v-if="clsfctonMsttable" justify="center">
            <v-col md="5">
              <v-card-title>
                区分マスタ
                <v-spacer></v-spacer>
                <v-text-field v-model="clsfctonmstSearch" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
              </v-card-title>
              <v-data-table class="ma-5" :headers="ClsfctonMst_headers" :search="clsfctonmstSearch" :items-per-page="itemsPerPage" :items="clsfctonMstData[0]" loading-text="読込中" no-data-text="データがありません。"></v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>            
    </v-tabs-items>
  </v-card>

</template>

<script>
import axios from "axios";
import preprocessing from './javascript/preprocessingCsv.js';
import updates from './javascript/updates.js';
import StorenallowList from "../store/modules/nallow.js";
import codeChng from './javascript/cstmrCodeChng.js';
export default {
  name: "nallows",
  data: () => ({
    usrMstDisplay: false,
    tab: null,
     tabs: null,
    items: [
          'ユーザーマスタ', '製品マスタ', 'リードタイムマスタ','得意先マッピングマスタ','区分マスタ'
        ],
      usrMst_headers: [
        { text: 'ユーザーコード', align:'center', sortable: true, value: 'USR_CD'},
        { text: 'ユーザー名', align:'center', value: 'USR_NM' },
        { text: 'メールアドレス', align:'center', value: 'ML_ADDR' },
        { text: 'マスタメンテナンスフラグ', align:'center', value: 'MST_MNTNNC_FLG' },
        ],
      prdctMst_headers: [
        { text: '品番CD', align:'center', sortable: true, value: 'PRDCT_CD'},
        { text: '品名', align:'center', value: 'PRDCT_NM' },
        { text: '',align:'center', value: 'CSTMR_PRDCT_CD' },
        { text: '数量', align:'center', value: 'QTY' },
        { text: '枠数', align:'center', value: 'MTH_QTY' },
        { text: '単価', align:'center', value: 'PRCE' },
        { text: '標準化区分', align:'center', value: 'STNDRD_KBN' },
        { text: '品番カテゴリ区分', align:'center', value: 'CTGRY_KBN' }
        ],
      cstmrMpgMst_headers: [
        { text: '得意先管理コード', align: 'start', width: 10, value: 'CSTMR_MNGMNT_NUM'},
        { text: '得意先品番管理コード', value: 'CSTMR_PRDCT_NUM'},
        { text: '識別', value: 'IDNTFCTON_CD' },
        { text: '受渡伝票No', value: 'DLVRY_SLP_NO'},
        { text: 'アイテムNO', value: 'ITM_NO'},
        { text: '受注区分', value: 'ORDR_KBN'},
        { text: '納期', value: 'DLVRY_TM'},
        { text: '出荷予定手番', value: 'SHPPNG_SCDLE_TRN'},
        { text: '出荷予定日', value: 'SHPPNG_SCDLE_DT'},
        { text: '出荷倉庫コード', value: 'SHPPNG_WREHS'},
        { text: '配送区分', value: 'SHPPNG_KBN'},
        { text: '客先注文NO.', value: 'CSTMR_ORDR_NO'},
        { text: '運賃区分', value: 'SHPPNG_EXPNS_KBN'},
        { text: '着日指定（日付）', value: 'DT_ARVL_DAGNTN'},
        { text: '着日指定（時間）', value: 'TM_ARVL_DAGNTN'},
        { text: '運送会社', value: 'SHPPNG_CMPNY'},
        { text: '送状付記内容1', value: 'INVICE_CNTNTS_1'},
        { text: '依頼者名', value: 'RQEST_NM'},
        { text: '経由先名', value: 'VIA_NM'},
        { text: '納品先名1', value: 'DLVRY_DSTNTON_NM_1'},
        { text: '納品先名2', value: 'DLVRY_DSTNTON_NM_2'},
        { text: '郵便番号', value: 'PST_CD'},
        { text: '都道府県', value: 'PREFECTURES'},
        { text: '納品住所1', value: 'DLVRY_ADDRSS_1'},
        { text: '納品住所2', value: 'DLVRY_ADDRSS_2'},
        { text: '現場', value: 'SCEN'},
        { text: '電話番号', value: 'PHNE_CD'},
        { text: '送状FAX依頼', value: 'INVICE_FAX_RQEST'},
        { text: '送状FAX有無', value: 'INVICE_FAX_PRSNC'},
        { text: '地図有無', value: 'MAP_PRSNC'},
        { text: '製品コード', value: 'PRDCT_CD'},
        { text: '製品名', value: 'PRDCT_NM'},
        { text: '数量', value: 'QTY'},
        { text: '単価', value: 'UNIT_PRC'},
        { text: '備考', value: 'REMARKS_1'},
        { text: '備考2_1', value: 'REMARKS_2_1'},
        { text: '備考2_2', value: 'REMARKS_2_2'},
        { text: '備考2_3', value: 'REMARKS_2_3'},
        { text: '備考2_4', value: 'REMARKS_2_4'},
        { text: '備考2_5', value: 'REMARKS_2_5'},
        { text: '備考2_6', value: 'REMARKS_2_6'},
        { text: '備考2_7', value: 'REMARKS_2_7'},
        { text: '備考2_8', value: 'REMARKS_2_8'},
        { text: '備考2_9', value: 'REMARKS_2_9'},
        { text: '備考2_10', value: 'REMARKS_2_10'},
      ],
      dlvryLdtmMst_headers: [
        { text: '都道府県コード', align: 'start', sortable: true, value: 'ORDR_NUM'},
        { text: '都道府県', value: 'PRFCTRS_NM'},
        { text: 'リードタイム', value: 'LD_TM'},
      ],
      ClsfctonMst_headers: [
        { text: '識別コード',align:'center',width: '25%', value: 'IDNTFCTON_CD'},
        { text: '分類番号', align:'center', value: 'CLSFCTON_NUM'},
        { text: '識別名', align:'center', value: 'IDNTFCTON_NM'},
        { text: '分類名', align:'center', value: 'CLSFCTON_NM'},
      ],      
    cstmr_value: "",
    usrmstdata: [],
    prdctMsData: [],
    cstmrMpgMstData: [],
    dlvryLdtmMstData: [],
    clsfctonMstData: [],
    cstmrs: [],
    codeChngResult: [],
    usrMstSearch: '',
    prdctMstSearch: '',
    cstmrMpgMstSearch: '',
    dlvryLdtmMstSearch: '',
    clsfctonmstSearch: '',
    showPassword_2: false,
    psswd_1: null,
    FileName: null,
    psswd_2: null,
    usr_id: null,
    itemsPerPage: 50,
    msts: [],
    error: null,
    usrMstTable: false,
    prdctMstTable: false,
    dlvryLdtmMstTable: false,
    cstmrMpgMstTable: false,
    clsfctonMsttable: false,
    compdialog: false,
    prdctdatadialog: false,
    resultdialog: false,
    errdialog: false,
  }),
  computed: {
    InputCheck() {
      return this.FileName;
    },
    cstmrCheck() {
      return this.cstmr_value;
    },
    Cstmr() {
      return StorenallowList.state.cstmrs;
    },
  },
  methods: {
    dialogReset(){
      this.compdialog = false;
      this.prdctdatadialog = false;
      this.resultdialog = false;
      this.errdialog = false;
      this.FileName = null;
    },    
    async usrMst() {
       this.usrmstdata = [];
      const usrmstresult = await axios.post('https://nasta-csvmngmnt.com/getEachMst',{parm: "usrMst"});
      this.usrmstdata.push(usrmstresult.data);
      this.usrMstTable = true;
    },
    async prdctMst() {
      this.prdctMst_headers[2].text = this.cstmr_value;
      this.prdctMsData = [];
      const prdctMstResult = await axios.post('https://nasta-csvmngmnt.com/getEachMst',{parm: "prdctMst",cstmr_cd: this.cstmr_value});
      this.prdctMsData.push(prdctMstResult.data);
      this.prdctMstTable = true;
    },
    async dlvryLdtmMst() {
      this.dlvryLdtmMstData = [];
      const dlvryLdtmMstResult = await axios.post('https://nasta-csvmngmnt.com/getEachMst',{parm: "dlvryLdtmMst"});
      this.dlvryLdtmMstData.push(dlvryLdtmMstResult.data);
      this.dlvryLdtmMstTable = true;
    },
    async cstmrMpgMst() {
      this.cstmrMpgMstData = [];
      const cstmrMpgMstResult = await axios.post('https://nasta-csvmngmnt.com/getEachMst',{parm: "cstmrMpgMst"});
      this.cstmrMpgMstData.push(cstmrMpgMstResult.data);
      this.cstmrMpgMstTable = true;
    },
    async clsfctonMst() {
      this.clsfctonMstData = [];
      const clsfctonMstResult = await axios.post('https://nasta-csvmngmnt.com/getEachMst',{parm: "clsfctonMst"});
      this.clsfctonMstData.push(clsfctonMstResult.data);
      this.clsfctonMsttable = true;
    },
    datInput(inputdatas) {
      if(inputdatas != null){
        var indentionResult = [];
        const file = inputdatas; 
        const reader = new FileReader();
        reader.readAsText(file,"UTF-8");
        reader.onload = () => {
          indentionResult = preprocessing.indention(reader.result);
          indentionResult.pop();
          let linesArr = [];
          for (let i = 0; i < indentionResult.length; i++) {
            linesArr[i] = preprocessing.csvSplit(indentionResult[i]);
          }
          this.msts = linesArr;
        };
      }
    },
    upload() {
      var mstUpdateResult = null;
      updates.mstUpdates(this.msts,this.$store.state.login.userId).then(result => {
        mstUpdateResult = result;
        this.resultdialog = true;
        if(mstUpdateResult == true){
          this.compdialog = true; 
        }else if(!mstUpdateResult){
          this.errdialog = true;
        }else{
          this.prdctdatadialog = true; 
        }
      });      
    },
    back(){
      this.$router.push("/orders");
    },    
  },
  async mounted() {
    var codeChngResult = null;// eslint-disable-line
    this.usr_id = this.$store.state.login.userId;
    this.cstmrs = StorenallowList.state.cstmrs;
    const cstmr = await axios.post('https://nasta-csvmngmnt.com/getCstmr');
    this.codeChngResult = codeChng.codeChange(cstmr.data);
  },
};
</script>

<style scoped>
.clearfix::after {
  content: " ";
  display: block;
  clear: both;
}
.text-center {
  width: 250px;
  font-size: 16px;
  line-height: 55px;
  height: 55px;
}
.inline{
  display: flex;
  margin: 10px;
}
.btns{
  margin: auto;
  width: 100%;
  float: right;
}
.sub{
  float: right;
}
span{
  color: red;
}
.elevation-1{
  max-width: 80%;
  height: auto;
  margin: 50px auto 100px auto;
}
.csv_input{
  width: 80%;
  padding: 25px 0 25px 0;
  margin: auto auto;
  text-align: center;
}
.ma{
  overflow-x: scroll;
}
.csvbtn{
  float:right;
  width: 150px;
  margin-top: 10px;
  margin-left: 50px;
  color: #ffffff;
}
</style>

