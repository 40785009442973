import date from "./date.js";
function validat(ShapingData){
  try{
    var ErrorMessages = [];
    for(var i = 0; i < ShapingData.length;i++){
      if(i == 0){
        if(!mandatory(ShapingData[i].SHPPNG_KBN)){
          ErrorMessages.push({ count: i, message: "配送区分は必ず選択してください。"});  
        }
        if(!mandatory(ShapingData[i].SHPPNG_WREHS)){
          ErrorMessages.push({ count:i , message: "出荷倉庫は必ず選択してください。"});  
        }
        if(nullCheck(ShapingData[i].SHPPNG_SCDLE_DT)){
          ShapingData[i].SHPPNG_SCDLE_DT = "";
        }
        if(nullCheck(ShapingData[i].DT_ARVL_DAGNTN)){
          ShapingData[i].DT_ARVL_DAGNTN = "";
        }             
        if(ShapingData[i].SHPPNG_SCDLE_DT != "" && date.mtchDatas() >= ShapingData[i].SHPPNG_SCDLE_DT){
          ErrorMessages.push({ count: i, message: "出荷予定日は今日以降の日付を指定して下さい。"}); 
        }
        if(!dtArvlDatePicker(ShapingData[i].DT_ARVL_DAGNTN)){
          ErrorMessages.push({ count: i, message: "着日指定は必須項目です。"});  
        }else if(ShapingData[i].SHPPNG_SCDLE_DT >= ShapingData[i].DT_ARVL_DAGNTN){
          ErrorMessages.push({ count:i , message: "着日指定は出荷予定日以降の日付を指定して下さい。"});
        }else if(date.mtchDatas() >= ShapingData[i].DT_ARVL_DAGNTN){
          ErrorMessages.push({ count:i , message: "着日指定は今日以降の日付を指定して下さい。"}); 
        }
        if(!string25(ShapingData[i].CSTMR_ORDR_NO)){
          ErrorMessages.push({ count: i, message: "件目の客先注文No.は25文字以下で入力してください。"});
        }
        if(string5(byteLngChck(ShapingData[i].TM_ARVL_DAGNTN))){
          ErrorMessages.push({ count: i, message: "件目の時間指定は5文字以下で入力してください。"});
        }
        if(!string20(ShapingData[i].VIA_NM)){
          ErrorMessages.push({ count: i, message: "経由先名は20文字以下で入力してください。"});
        }
        if(!string24(ShapingData[i].DLVRY_DSTNTON_NM_1)){
          ErrorMessages.push({ count: i, message: "納品先名1は24文字以下で入力してください。"});
        }
        if(!string20(ShapingData[i].DLVRY_DSTNTON_NM_2)){
          ErrorMessages.push({ count: i, message: "納品先名2は20文字以下で入力してください。"});
        }
        if(!pstCd(ShapingData[i].PST_CD)){
          ErrorMessages.push({ count: i, message: "郵便番号は郵便番号形式で入力してください。"});  
        }
        if(!prefecturesString5(ShapingData[i].PREFECTURES)){
          ErrorMessages.push({ count: i, message: "都道府県は5文字以内で入力してください。"});  
        }else if(ShapingData[i].SHPPNG_SCDLE_DT.length == 0 && ShapingData[i].DT_ARVL_DAGNTN.length > 0 && nullToEmpty(ShapingData[i].PREFECTURES).length == 0){
          ErrorMessages.push({ count: i, message: "都道府県を選択するか出荷予定日を選択して下さい。"});
        }
        if(!string24(ShapingData[i].DLVRY_ADDRSS_1)){
          ErrorMessages.push({ count: i, message: "納品住所1は24文字以下で入力してください。"});  
        }else if(addrss1String50(byteLngChck(ShapingData[i].DLVRY_ADDRSS_1)) == 2){
          ErrorMessages.push({ count: i, message: "納品住所1は必須項目です。"});
        }
        if(!string24(ShapingData[i].DLVRY_ADDRSS_2)){
          ErrorMessages.push({ count: i, message: "納品住所2は24文字以下で入力してください。"});
        }
        if(!phneCd(ShapingData[i].PHNE_CD)){
          ErrorMessages.push({ count: i, message: "電話番号は電話番号形式で入力してください。"});  
        }
        if(!mandatory(ShapingData[i].SHPPNG_EXPNS_KBN)){
          ErrorMessages.push({ count: i, message: "運賃区分は必ず選択してください。"});  
        }
        if(!string34(ShapingData[i].SCEN)){
          ErrorMessages.push({ count: i, message: "物件名は34文字以下で入力してください。"});  
        }
        if(!string28(ShapingData[i].INVICE_CNTNTS_1)){
          ErrorMessages.push({ count: i, message: "送状付記内容1は28文字以下で入力してください。"});
        }
        if(!string400(ShapingData[i].REMARKS)){
          ErrorMessages.push({ count: i, message: "備考は400文字以内で入力してください。"});  
        }
        if(!string400(ShapingData[i].REMARKS2_1)){
          ErrorMessages.push({ count: i , message: "備考2は400文字以内で入力してください。"});  
        }

      }
      if(!string25(ShapingData[i].PRDCT_CD)){
        ErrorMessages.push({ count: i, message: i+1 + "行目の製品コードは25文字以下で入力してください。"});  
      }
      if(!string46(ShapingData[i].PRDCT_NM)){
        ErrorMessages.push({ count: i, message: i+1 + "件目の品名は50文字以下で入力してください。"});  
      }
      if(!string25(ShapingData[i].CSTMR_PRDCT_CD)){
        ErrorMessages.push({ count: i, message: i+1 + "行目の客先製品コードは25文字以下で入力してください。"});  
      }
      if(ShapingData[i].QTY.length >= 5){
        ErrorMessages.push({ count: i , message: i+1 + "行目の受注数は5文字以下で入力して下さい。"});
      }
      if(!numberLngth(ShapingData[i].UNIT_PRC)){
        ErrorMessages.push({ count: i , message: i+1 + "行目の単価は整数を入力して下さい。"});  
      }
      if(!PRCnumberLngth(ShapingData[i].UNIT_PRC)){
        ErrorMessages.push({ count: i , message: i+1 + "行目の単価は8文字以下で入力して下さい。"});  
      }
    }
    
    if(ErrorMessages.length == 0){
      return true
    }else{
      return ErrorMessages
    }
  }catch{
    alert("処理に失敗しました管理者に連絡して下さい。");
  }
}
function nullToEmpty(inputdata) {
  if(inputdata == null){
    inputdata = ""
  }
  return inputdata
}
function nullCheck(inputdata){
  var re = true;
  if(inputdata == null){
    return re
  }else{
    re = false
  }
  return re
}
function string5(inputdata) {
  var re = false;
  if(inputdata >= 5){
    re = true
  }
  return re
}
function prefecturesString5(inputdata) {
  var re = /^.{0,5}$/;
  return re.test(inputdata);
}
function string20(inputdata) {
  var re = true;
  if(inputdata.length > 20){
    re = false
  }
  return re
}
function string24(inputdata) {
  var re = true;
  if(inputdata.length > 24){
    re = false
  }
  return re
}
function string25(inputdata) {
  var re = true;
  if(inputdata.length > 25){
    re = false
  }
  return re
}
function string28(inputdata) {
  var re = true;
  if(inputdata.length > 28){
    re = false
  }
  return re
}
function string34(inputdata) {
  var re = true;
  if(inputdata.length > 34){
    re = false
  }
  return re
}
function string46(inputdata) {
  var re = true;
  if(inputdata.length > 46){
    re = true
  }
  return re
}
function addrss1String50(inputdata) {
  var re = null;
  if(inputdata >= 50){
    re = 1
    return re
  }else if(inputdata == 0){
    re = 2
    return re
  }else{
    return re
  }
}
function string400(inputdata) {
  var re = /^[\s\S\n]{0,400}$/;
  return re.test(inputdata);
}
function dtArvlDatePicker(inputdata) {
  if(inputdata == null){
    inputdata = "";
  }
  if(inputdata.length > 0){
    return true;
  }
  return false
}
function numberLngth(inputdata) {
  var re = /^([1-9]\d*|0)$/;
  return re.test(inputdata);
}
function PRCnumberLngth(inputdata) {
  if(inputdata.length >= 8){
    return false;
  }else{
    return true
  }
}
function mandatory(inputdata) {
  var re = /^([A-Z0-9]{1,1})$/;
  return re.test(inputdata);
}
function pstCd(inputdata) {
  var re = /^([0-9]{3}-[0-9]{4})$/;
  return re.test(inputdata);
}
function phneCd(inputdata) {
  if(inputdata.length >= 1){ 
  var re = /^0\d{2,3}-\d{1,4}-\d{4}$/;
  return re.test(inputdata);
  }
  return true
}
function shppngScdleTrn(inputdata) {
  var re = /^([0-9]{0,2})$/;
  return re.test(inputdata);
}
function byteLngChck (str) {
  var length = 0;
  if(str == null){
    str = '';
  }else{
  for (var i = 0; i < str.length; i++) {
    var c = str.charCodeAt(i);
    if ((c >= 0x0 && c < 0x81) || (c === 0xf8f0) || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) {
      length += 1;
    } else {
      length += 2;
    }
  }
}
  return length;
}
export default {
  validat,
  shppngScdleTrn
}