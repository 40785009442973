<template>
  <v-container style="height: auto;">
    <v-row>
      <v-col>
        <v-card height="120px"  elevation="2"  class="v-container">
          <div class="csv_input">
            <v-file-input class="file_input" type="file" accept=".csv" v-model="fileName" counter clearable @change="datInput" label="CSVファイル"></v-file-input>
            <v-card-actions>
              <v-select height="23px" item-text="CSTMR_NM" label="得意先" item-value="CSTMR_MNGMNT_NUM" v-model="cstmrtName" :items="mapCstmrMngmntNum" class="cumpany-styled-input"></v-select>
              <v-btn :disabled="!InputCheck" class="csvbtn" elevation="9" color="#00ACF9"  @click="upload">アップロード</v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-card v-if="vali" outlined style="width:auto;border:1px solid #FF5252;margin: 30px auto;" height="auto">
      <v-col lg="12" md="12">
        <p style="color:#FF5252;"><v-icon color="#FF5252" style="padding:0 5px 5px 0;">mdi-alert-circle</v-icon>登録内容にエラーがあります。</p>
        <p v-for="error in errorMessages" :key="error.errorMessages" >・<span ><v-icon color="#FF5252" @click="count(error.count)">mdi-vector-arrange-above</v-icon></span>{{error.message}}</p>
      </v-col>	
    </v-card>
    <v-container v-if="display" style="height: auto">
      <v-dialog v-model="dialog" persistent width="400" max-width="800">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="update" color="#00ACF9" elevation="5" v-bind="attrs" v-on="on" @click="frameNumCheck">登録</v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h5" style="background-color:#00ACF9">登録</v-card-title>
          <v-card-title class="pa-5" style="color: rgba(0,0,0,.8);font-size: 16px;">登録して宜しいですか？</v-card-title>
          <v-data-table v-if="frameFlg" :headers="frameheaders" :items="frameResult" fixed-header hide-default-footer loading-text="読込中" no-data-text="データがありません。"></v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">
              cancel
            </v-btn>
            <v-btn color="green darken-1" text @click="Insert">
            ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>    
      <v-dialog v-if="resultDialog == true" v-model="resultDialog" persistent max-width="400">
        <v-card>
          <v-card-title v-if="compDialog == true" class="text-h5" style="background-color:#43A047"></v-card-title>
          <v-card-title v-if="errDialog == true" class="text-h5" style="background-color:#FF5252"></v-card-title>
          <v-card-title v-if="compDialog == true">登録が完了しました。</v-card-title>
          <v-card-title v-if="errDialog == true">登録出来ませんでした。<br>管理者に連絡してください。</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="router">
              ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
        <span id="ordrhld">
          {{ maxModel }}件中 {{ Model + 1 }}件目
        </span>
      <v-form ref="focusThis">
        <v-carousel dark :cycle="false" height="auto" hide-delimiters show-arrows-on-hover v-model="Model" class="carousel">
          <v-carousel-item v-for="(CSF,i) in shapingData" :key="i">
            <v-card style="width:auto;" height="auto" elevation="2">
              <v-row style="margin: auto;padding-bottom: 5px;">
               <v-col lg="6" md="6">	
                  <div class="inline_1 clearfix">  	
                    <div class="text-center">配送区分<span>*</span></div>	
                    <v-select height="25px" v-model="CSF[0].SHPPNG_KBN" class="styled-input split_2" item-text="CLSFCTON_NM" item-value="CLSFCTON_NUM" :items="Hskb" ></v-select>	
                    <div class="text-center">出荷倉庫<span>*</span></div>          	
                    <v-select height="25px" item-text="CLSFCTON_NM" item-value="CLSFCTON_NUM" :items="SOKB" v-model="CSF[0].SHPPNG_WREHS"  class="styled-input split_2" color="blue"></v-select>	
                  </div>		
                  <div class="inline_1 clearfix">	
                    <div class="text-center">出荷予定日</div>
                    <v-menu :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field class="pickers" readonly v-model="CSF[0].SHPPNG_SCDLE_DT" height="25" maxlength="10" clearable v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker locale="ja" v-model="CSF[0].SHPPNG_SCDLE_DT"></v-date-picker>
                    </v-menu>
                    <div class="text-center small">着日指定<span>*</span></div>          	
                    <v-menu :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field class="pickers" v-model="CSF[0].DT_ARVL_DAGNTN" height="25" maxlength="10" readonly clearable v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker locale="ja" v-model="CSF[0].DT_ARVL_DAGNTN"></v-date-picker>
                    </v-menu>                    
                  </div>
                  <div class="inline_1 clearfix">	
                  <div class="text-center">客先注文No.</div>          	
                    <v-text-field height="25px" counter="25" :rules="rules_25" v-model="CSF[0].CSTMR_ORDR_NO" class="styled-input"   type="text"></v-text-field>
                    <div class="text-center">時間指定</div>	
                    <v-text-field height="25px" v-model="CSF[0].TM_ARVL_DAGNTN" class="styled-input"  type="text"></v-text-field>	                    
                  </div>
                  <div class="inline_1 clearfix">	
                    <div class="text-center">経由先名</div>          	
                    <v-text-field height="25px" counter="20" :rules="rules_20" v-model="CSF[0].VIA_NM" class="styled-input"   color="blue" type="text"></v-text-field>	
                  </div>
                  <div class="inline_1 clearfix">	
                    <div class="text-center">納品先名1</div>          	
                    <v-text-field height="25px" counter="24" :rules="rules_24" v-model="CSF[0].DLVRY_DSTNTON_NM_1" class="styled-input"   type="text"></v-text-field>	
                  </div>	
                  <div class="inline_1 clearfix">	
                    <div class="text-center">納品先名2</div>          	
                    <v-text-field height="25px" counter="24" :rules="rules_24" v-model="CSF[0].DLVRY_DSTNTON_NM_2" class="styled-input"   type="text"></v-text-field>	
                  </div>	
                  <div class="inline_1 clearfix">	
                    <div class="text-center">郵便番号<span>*</span></div>	
                    <v-text-field height="25px" counter="8" :rules="rules_8" v-model="CSF[0].PST_CD" class="styled-input split_2"  type="text"></v-text-field>
                    <div class="text-center">都道府県</div>	
                    <v-autocomplete hide-no-data height="25px" item-text="PRFCTRS_NM" item-value="PRFCTR_NM" :items="PREF" v-model="CSF[0].PREFECTURES" class="styled-input split_2"></v-autocomplete>
                  </div>	
                  <div class="inline_1 clearfix">	
                    <div class="text-center">納品住所1<span>*</span></div>          	
                    <v-text-field height="25px" counter="24" :rules="rules_24" v-model="CSF[0].DLVRY_ADDRSS_1" class="styled-input"  type="text"></v-text-field>	
                  </div>	
                  <div class="inline_1 clearfix">	
                    <div class="text-center">納品住所2</div>          	
                    <v-text-field height="25px" counter="24" :rules="rules_24" v-model="CSF[0].DLVRY_ADDRSS_2" class="styled-input"  type="text"></v-text-field>	
                  </div>	                  
                  <div class="inline_1 clearfix">	
                    <div class="text-center">電話番号</div>	
                    <v-text-field height="25px" counter="14" :rules="rules_13" v-model="CSF[0].PHNE_CD" class="styled-input"  type="text"></v-text-field>	
                  </div>
                  <div class="inline_1 clearfix">
                    <div class="text-center">運賃区分<span>*</span></div>          	
                    <v-select height="25px" v-model="CSF[0].SHPPNG_EXPNS_KBN" class="styled-input split_2" type="text" item-text="CLSFCTON_NM" item-value="CLSFCTON_NUM" :items="Uckb" ></v-select>	
                    <div class="text-center">運送会社</div>
                    <v-select height="25px" v-model="CSF[0].SHPPNG_CMPNY" class="styled-input split_2" type="text" item-text="CLSFCTON_NM" item-value="CLSFCTON_NUM"  :items="trsp" ></v-select>                    
                  </div>	      
                </v-col>	
                <v-col lg="6" md="6">	
                  <div class="inline_1 clearfix">	
                    <div class="text-center">物件名</div>          	
                    <v-text-field height="25px" counter="34" :rules="rules_34" v-model="CSF[0].SCEN" class="styled-input"  type="text"></v-text-field>	                    
                  </div>
                  <div class="inline_2 clearfix" >	
                    <div class="text-center small">送状FAX依頼</div>	
                    <v-select height="25px" v-model="CSF[0].INVICE_FAX_RQEST" class="styled-input split_3" item-text="CLSFCTON_NM" item-value="CLSFCTON_NUM" :items="UMKB" ></v-select>	
	
                    <div class="text-center">送状FAX有無</div>
                    <v-select height="25px" v-model="CSF[0].INVICE_FAX_PRSNC" class="styled-input split_3" item-text="CLSFCTON_NM" item-value="CLSFCTON_NUM" :items="UMKB" ></v-select>          	

                    <div class="text-center">地図有無</div>
                    <v-select height="25px" v-model="CSF[0].MAP_PRSNC" class="styled-input split_3" item-text="CLSFCTON_NM" item-value="CLSFCTON_NUM" :items="UMKB" ></v-select>          	
                  </div>	
                  <div class="inline_1 clearfix" >	
                    <div class="text-center">送状付記内容1</div>          	
                    <v-text-field height="25px" counter="20" :rules="rules_20" v-model="CSF[0].INVICE_CNTNTS_1" class="styled-input" type="text" ></v-text-field>	
                  </div>                  
                  <div class="inline_2_remarks clearfix">	
                  <div class="text-center area">備考</div>
                    <v-textarea v-model="CSF[0].REMARKS" no-resize counter class="styled-input-area" ></v-textarea>
                  </div>
                  <div class="inline_2_remarks clearfix">	
                  <div class="text-center area">備考2</div>
                    <v-textarea v-model="CSF[0].REMARKS2_1" no-resize counter class="styled-input-area" ></v-textarea>
                  </div>                  
                <div class="inline_2 clearfix">	
                <div class="text-center">保留区分</div>	
                <v-checkbox style="position:relative;bottom:9px;left:10px" v-model="Hold[i]" label="保留" color="orange" >	
                  <template v-slot:label>	
                  </template>
                </v-checkbox>
                </div>
                </v-col>
              </v-row>
            </v-card>
            <v-data-table :headers="headers" :items="shapingData[i]" :items-per-page="itemsPerPage" disable-sort fixed-header height="260px" hide-default-footer loading-text="読込中" no-data-text="データがありません。" class="elevation-1">
              <template v-slot:item.PRDCT_CD="{ item }">
                <v-combobox height="25px" item-text="PRDCT_CD" item-value="PRDCT_CD" :items="PRDC" v-model="item.PRDCT_CD" ref="userComboBox" class="styled-input_2" @change="srchPrdcts(item.PRDCT_CD)"></v-combobox>
              </template>
              <template v-slot:item.CSTMR_PRDCT_CD="{ item }">
                <v-text-field height="25px" v-model="item.CSTMR_PRDCT_CD" class="styled-input_2"  type="text"></v-text-field>
              </template>
              <template v-slot:item.PRDCT_NM="{ item }">
                <v-text-field height="25px" v-model="item.PRDCT_NM" class="styled-input_2"  type="text"></v-text-field>
              </template>
              <template v-slot:item.QTY="{ item }">
                <v-text-field height="25px" v-model="item.QTY" class="styled-input_2 "  type="number"></v-text-field>
              </template>
              <template v-slot:item.UNIT_PRC="{ item }">
                <v-text-field height="25px" v-model="item.UNIT_PRC" class="styled-input_2 "  type="text" suffix="円"></v-text-field>
              </template>
            </v-data-table>
          </v-carousel-item>
        </v-carousel>
      </v-form>
    </v-container>
    <v-snackbar color="red darken-2" v-model="notCsvFlg" :multi-line="multiLine">
      <template v-slot:action="{ attrs }">{{text}}
        <v-btn color="#FFFFFF" text v-bind="attrs" @click="notCsvFlg = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>        
  </v-container>
</template>

<script>
import axios from "axios";
import CleateItem from "../store/modules/cleateItem.js";
import uploads from './javascript/uploads.js';
import inserts from './javascript/inserts.js';
import validats from './javascript/validats.js';
import prdctsrch from './javascript/prdctSrch.js';
import slctPrdctNum from './javascript/slctPrdctNum.js';
import preprocessing from './javascript/preprocessingCsv.js';

export default {
  name: "cleatItem",
  data: () => ({
    rules_13: [v => v.length < 14],
    rules_24: [v => v.length < 25],
    rules_25: [v => v.length < 26],
    rules_20: [v => v.length < 21],
    rules_34: [v => v.length < 35],
    rules_8: [v => v.length < 9],
    test: [],
    header: [],
    display: false,
    itemsPerPage: 100,
    usrCd: null,
    cstmrMngmntNum: [],
    mapCstmrMngmntNum: [],
    cstmrtName: null,
    cstmrPrdctNum: null,
    fileName: null,
    dialog: false,
    resultDialog: false,
    compDialog: false,
    errDialog: false,
    errorMessages: [],
    error: null,
    multiLine: true,
    Hold: [],
    cstmrPrdctRslt: [],
    initiaLizeData:[],
    frameResult:[],
    Model: null,
    frameFlg: false,
    notCsvFlg: false,
    maxModel: null,
    vali: false,
    shapingData: [],
    result: [],
    workers: [],
    text: `取込データがCSVファイルではありません。`,  
    headers: [
      { text: '製品コード', align:'center',width:'15%', value:'PRDCT_CD'},
      { text: '品名', align:'center',width:'40%',value: 'PRDCT_NM'},
      { text: '客先製品コード',align:'center', width:'25%',value: 'CSTMR_PRDCT_CD'},
      { text: '受注数', align:'center',width:'6%',value: 'QTY'},
      { text: '単価', align:'center',width:'8%',value: 'UNIT_PRC'},
    ],
    frameheaders:[
      {text: '出荷予定日', align:'center',sortable: true, value: 'SHPPNG_SCDLE_DT'},
      {text: '標準化区分', align:'center', value: 'CLSFCTON_NM'},
      {text: '使用枠数', align:'center', value: 'MTH_QTY'},
    ]    
  }),
  computed: {   
    InputCheck() {
      return this.cstmrtName && this.fileName;
    },
    Hskb(){
      return CleateItem.state.hskbs;
    },
    Uckb(){
      return CleateItem.state.uckbs;
    },
    trsp(){
      return CleateItem.state.trsps;
    },
    jukb2(){
      return CleateItem.state.jukb2s;
    },
    SOKB(){
      return CleateItem.state.sokbs;
    },
    UMKB(){
      return CleateItem.state.umkbs;
    },
    PREF(){
      return CleateItem.state.prefs;
    },
    PRDC(){
      return CleateItem.state.prdcts;
    }
  },
  methods: {
   async srchPrdcts(val) {
      if(val.PRDCT_CD != null){
       const srchPrdcts = await axios.post('https://nasta-csvmngmnt.com/getSrchPrdcts',{PRDCT_CD : val.PRDCT_CD});// eslint-disable-line
        for(var i = 0; i < this.shapingData.length; i++){
          for(var j = 0; j < this.shapingData[i].length; j++){
            if(val.PRDCT_CD == this.shapingData[i][j].PRDCT_CD.PRDCT_CD){
              this.shapingData[i][j].PRDCT_NM = srchPrdcts.data[0].PRDCT_NM;
              this.shapingData[i][j].UNIT_PRC = srchPrdcts.data[0].PRCE;
              this.shapingData[i][j].PRDCT_CD = this.shapingData[i][j].PRDCT_CD.PRDCT_CD
            }
          }
        }
        return
      }
    }, 
    router(){
      this.compDialog = false;
      this.$router.push("/orders");
    },    
    count(num){
      this.Model = num;
    },
    datInput(inputdatas) {
      if(inputdatas != null){
        if(inputdatas.type == 'application/vnd.ms-excel' || inputdatas.type == 'text/csv'){
          var indentionResult = [];
          var heads = [];
          const file = inputdatas; 
          const reader = new FileReader();
          reader.readAsText(file,"Shift_JIS");
          reader.onload = () => {
            indentionResult = preprocessing.indention(reader.result);
            heads = preprocessing.indention(reader.result);
            indentionResult.shift();
            indentionResult.pop();
            let linesArr = [];
            let headsArr = [];
            headsArr[0] = preprocessing.csvSplit(heads[0]);
            for (let i = 0; i < indentionResult.length; i++) {
              linesArr[i] = preprocessing.csvSplit(indentionResult[i]);
            }
            this.workers = linesArr;
            this.header = headsArr;
          };
        }else {
          alert(inputdatas.type);
          this.fileName = null;
          this.notCsvFlg = true;
        }
      }
      
    },
    upload() {
      var cstmrPrdctNum = null;
      this.errorMessages = [];
      this.vali = false;
      this.shapingData = uploads.uploads(this.cstmrMngmntNum,this.cstmrtName,this.workers,this.header).shapingData;
      slctPrdctNum.prdctNumget(this.cstmrMngmntNum,this.cstmrtName).then(result => {
        cstmrPrdctNum = result;
        prdctsrch.search(this.shapingData,cstmrPrdctNum);
      });
      this.initiaLizeData = uploads.uploads(this.cstmrMngmntNum,this.cstmrtName,this.workers,this.header).initiaLizeData;
      this.display = true;
      this.maxModel = this.shapingData.length;
    },
    AllClaer() {
      this.shapingData = [];
      this.initiaLizeData = [];
    },
    frameNumCheck() {
      this.frameResult = [];
      this.frameFlg = false;
      prdctsrch.getframe(this.shapingData).then(result => {
        this.frameResult = result;
        if(this.frameResult.length > 0){
          this.frameFlg = true;
        }
      });
    },
    Insert(){
      val = null;
      var insertResult = null;
      var val = validats.validat(this.shapingData);
      this.errorMessages = [];
      this.dialog = false;
      if(val == true){
        inserts.inserts(this.shapingData,this.initiaLizeData,this.Hold,this.usrCd).then(result => {
          insertResult = result;
          if(insertResult){
            this.vali = false;
            this.resultDialog = true;
            this.compDialog = true;
          }else{
            this.resultDialog = true;
            this.errDialog = true;
          }
        });       
      }else{
        this.errorMessages = val;
        this.vali = true;
      }
    },
  },
  async mounted() {
    try{
      const cstmrMngmntNumResult = await axios.post("https://nasta-csvmngmnt.com/getcstmrMngmntNum");
      this.$store.dispatch("cleateItem/clsfcton");
      this.$store.dispatch("cleateItem/Prefectures");
      this.$store.dispatch("cleateItem/Prdcts");
      this.cstmrMngmntNum = cstmrMngmntNumResult.data;
      for(var i = 0;i < cstmrMngmntNumResult.data.length; i++){
        this.mapCstmrMngmntNum.push(...[{CSTMR_MNGMNT_NUM:cstmrMngmntNumResult.data[i].CSTMR_MNGMNT_NUM, CSTMR_NM:cstmrMngmntNumResult.data[i].CSTMR_CD + ' : '+ cstmrMngmntNumResult.data[i].CSTMR_NM}]);
      }
      this.usrCd = this.$store.state.login.userId;
    }catch{
      alert("処理に失敗しました。");
    }
  },
};
</script>

<style scoped>
.carousel{
  height: 100%;
}
span{
  color: red;
}
.text-center {
  width: 120px;
  font-size: 15px;
  padding-right: 5px;
  line-height: 45px;
  text-align: left;
  height: 45px;
  background-color: #EFF8FF;
}
.area {
  margin: auto auto;
  width: 120px;
  font-size: 14px;
  padding-right: 5px;
  line-height: 35px;
  height: 100%;
}
.text-center_2 {
  font-size: 14px;
  padding-right: 5px;
  line-height: 45px;
  height: 45px;
  background-color: #EFF8FF;
}
.clearfix::after {
  content:" ";
  display:block;
  clear:both;
}
.inline_1{
  display: flex;
  height: 45px;
  margin-bottom: 1px;
}
.container{
  height: 420px;
}

.styled-input {
  height: 80px;
font-size: 16px;
}
.styled-input_test {
  height: 30px !important;
  background-color: cyan;
  font-size: 16px;
}
.split_2{
  width: 10%;
  font-size: 16px;
}
.split_3{
  width: 10%;
    font-size: 16px;
}
.styled-input-area {
width: auto;
margin: 0;
font-size: 16px;
height: 185px;
background-color: #ffffff;
}
.cumpany-styled-input{
  height: 45px;
font-size: 14px;
margin: 0 0 0 30px;
}
.styled-input_2 {
  height: 35px;
font-size: 16px;
}
.ev_small {
  width: 150px;
}
.small {
  min-width: 100px;
}
.small_2 {
  min-width: 50px;
  background-color: cyan;
}
.inline_2 {
  display: flex;
  height: 45px;
}
.inline_2_remarks {
  display: flex;
  margin-bottom: 1px;
  background-color: #EFF8FF;
}
.inline_3 {
  display: flex;
  height: 45px;
  margin-bottom: 1px;
}
.update{
  height: 60px;
  float: right;
  width: 100px;
  right: 10px;
  color: #ffffff;
  margin-bottom:20px;
}
.v-application .--text {
    color: #2D83D6 !important;
    caret-color: #2D83D6 !important;
}
.container2 {
  margin-top: 20px;
  height: 250px;
}
.elevation-1{
  max-width: 100%;
  height: auto;
  margin: 50px auto 100px auto;
}
.zasd {
  width: 100%;
  height: 35px;
  font-size: 14px;
}

#ordrhld{
  border-bottom: solid 5px #ffff7f;
  width:  auto;
  position: relative;
  bottom: -30px;
  color: #000000;
}
.v-container{
  width: 100%;
  height: 100%;
  margin-top: -50px;
}
.csv_input{
  width: 80%;
  padding-top: 25px;
  margin: auto auto;
  text-align: center;
}
.file_input {
  margin: auto auto;
  float:left;
}
.csvbtn{
  float:right;
  width: 150px;
  margin-top: 10px;
  margin-left: 50px;
  color: #ffffff;
}
.pickers{
  width: 185px;
}
</style>

