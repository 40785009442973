
import axios from 'axios'

import narrow from "../../components/narrow.vue"

export default {
  namespaced: true,
  state: {
    messages: [],
    csvkbs: [],
  },
  
  mutations: {
    output(state, nallowList) {
      state.messages = nallowList;
    },
    CSVKB(state, csvkbList) {
      state.csvkbs += csvkbList;
    },
  }, 
  actions: {
    async clsfcton({ commit }) {
      try {
        const clsfcton = await axios.post('https://nasta-csvmngmnt.com/getClsfcton');  
        commit('output', clsfcton.data);
        for(var i = 0; i < clsfcton.data.length; i++){
          if(clsfcton.data[i].IDNTFCTON_CD == 'CSVKB'){
            commit('CSVKB', clsfcton.data[i]);
          }
        }
        narrow.computed.Csvkb();
          return;
      } catch{
        alert('処理に失敗しました。管理者へお問合せ下さい。');
        return;
      }
    },
  },

}
