export default {
  namespaced: true,
  state: {
    isLogin: false,
    userId: null,
    userNm: null,
    mstmnt: null,
    error: false,
  },
  mutations: {
    LOGOUT(state) {
      state.isLogin = false;
      state.userId = null;
      state.userNm = null;
    },
  },   
  actions: {
    async logput({ commit }) {
      try {
        commit('LOGOUT');
      } catch{
        alert('エラーが発生しました。\n管理者へお問合せ下さい。');
        return;
      }
    },    
  }  
}