import axios from "axios";
import nowdate from './date.js';
async function updates(DLVRY_SLP_NO, SHPPNG_KBN,SHPPNG_WREHS,SHPPNG_SCDLE_DT,CSTMR_ORDR_NO,VIA_NM,DLVRY_DSTNTON_NM_1,DLVRY_DSTNTON_NM_2,PST_CD,PREFECTURES,DLVRY_ADDRSS_1,DLVRY_ADDRSS_2,PHNE_CD,SCEN,DT_ARVL_DAGNTN,TM_ARVL_DAGNTN,SHPPNG_EXPNS_KBN,SHPPNG_CMPNY,INVICE_FAX_RQEST,INVICE_FAX_PRSNC,MAP_PRSNC,INVICE_CNTNTS_1,REMARKS,REMARKS2_1,STTS_KBN,USR_CD,MNGMNT_NUM,PRDCTS){
  try{
    var dlvryIdtmdata = [];
    var ld_tm = "";
    var lstData = [];
    const DlvryIdtmResult = await axios.post("https://nasta-csvmngmnt.com/getDlvryIdtm",{
      PREFECTURES: PREFECTURES
    });
    if(DlvryIdtmResult.data.length > 0){
      dlvryIdtmdata.push(DlvryIdtmResult.data);
      ld_tm = DlvryIdtmResult.data[0].LD_TM;
    }else{
      ld_tm = "";
    }
    for(var i = 0;i < PRDCTS.length; i++){
      lstData.push([
        {
          DLVRY_SLP_NO : stringcut(DLVRY_SLP_NO + nowdate.dates()),
          SHPPNG_KBN : SHPPNG_KBN,
          SHPPNG_WREHS : SHPPNG_WREHS,
          SHPPNG_SCDLE_DT : shppngDateMolding(SHPPNG_SCDLE_DT),
          CSTMR_ORDR_NO : stringcut(CSTMR_ORDR_NO),
          DLVRY_TM : dateMolding(DT_ARVL_DAGNTN),
          VIA_NM : VIA_NM,
          DLVRY_DSTNTON_NM_1 : DLVRY_DSTNTON_NM_1,
          DLVRY_DSTNTON_NM_2 : DLVRY_DSTNTON_NM_2,
          PST_CD : PST_CD,
          SHPPNG_SCDLE_TRN : ld_tm,
          PREFECTURES : PREFECTURES,
          DLVRY_ADDRSS_1 : DLVRY_ADDRSS_1,
          DLVRY_ADDRSS_2 : DLVRY_ADDRSS_2,
          PHNE_CD : PHNE_CD,
          SCEN : SCEN,
          DT_ARVL_DAGNTN : dateMolding(DT_ARVL_DAGNTN),
          TM_ARVL_DAGNTN : TM_ARVL_DAGNTN,
          SHPPNG_EXPNS_KBN : SHPPNG_EXPNS_KBN,
          SHPPNG_CMPNY : SHPPNG_CMPNY,
          INVICE_FAX_RQEST : INVICE_FAX_RQEST,
          INVICE_FAX_PRSNC : INVICE_FAX_PRSNC,
          MAP_PRSNC : MAP_PRSNC,
          INVICE_CNTNTS_1 : INVICE_CNTNTS_1,
          REMARKS : REMARKS,
          REMARKS2 : remarkMolding(REMARKS2_1),
          STTS_KBN : STTS_KBN,
          PRDCT_CD : PRDCTS[i].PRDCT_CD,
          PRDCT_NM : PRDCTS[i].PRDCT_NM,
          CSTMR_PRDCT_CD : PRDCTS[i].CSTMR_PRDCT_CD,
          QTY : PRDCTS[i].QTY,
          SHPPNG_UNIT_CD : PRDCTS[i].SHPPNG_UNIT_CD,
          UNIT_PRC : PRDCTS[i].UNIT_PRC,
          USR_CD : USR_CD,
          MNGMNT_NUM : MNGMNT_NUM,
          ITM_NO : PRDCTS[i].ITM_NO,        
        }
      ])
    }
    const UpdateMaps = await axios.post("https://nasta-csvmngmnt.com/updateMaps",{lstData});
    if(UpdateMaps.data != "ERROR"){
      return true;
    }else{
      return false;      
    }
  }catch{
    return false;
  }
}

async function mstUpdates(msts,usr_cd){
  try{
    var mstdata = [];
    switch (msts[0][0]) {
      case "prdctMst":
      msts.shift();
      for(var i = 0;i < msts.length; i++){
        var prdctMsts = {
          MST_NM: "prdct_mst",
          CRUD: msts[i][0],
          PRDCT_CD : msts[i][1],
          PRDCT_NM : msts[i][2],
          CSTMR1_PRDCT_CD : msts[i][3],
          CSTMR2_PRDCT_CD : msts[i][4],
          CSTMR3_PRDCT_CD : msts[i][5],
          CSTMR4_PRDCT_CD : msts[i][6],
          CSTMR5_PRDCT_CD : msts[i][7],
          CSTMR6_PRDCT_CD : msts[i][8],
          CSTMR7_PRDCT_CD : msts[i][9],
          CSTMR8_PRDCT_CD : msts[i][10],
          CSTMR9_PRDCT_CD : msts[i][11],
          CSTMR10_PRDCT_CD : msts[i][12],
          CSTMR11_PRDCT_CD : msts[i][13],
          CSTMR12_PRDCT_CD : msts[i][14],
          CSTMR13_PRDCT_CD : msts[i][15],
          CSTMR14_PRDCT_CD : msts[i][16],
          CSTMR15_PRDCT_CD : msts[i][17],
          CSTMR16_PRDCT_CD : msts[i][18],
          CSTMR17_PRDCT_CD : msts[i][19],
          CSTMR18_PRDCT_CD : msts[i][20],
          CSTMR19_PRDCT_CD : msts[i][21],
          CSTMR20_PRDCT_CD : msts[i][22],
          CSTMR21_PRDCT_CD : msts[i][23],
          CSTMR22_PRDCT_CD : msts[i][24],
          CSTMR23_PRDCT_CD : msts[i][25],
          CSTMR24_PRDCT_CD : msts[i][26],
          CSTMR25_PRDCT_CD : msts[i][27], 
          QTY : msts[i][28],
          MTH_QTY : msts[i][29],
          PRCE : msts[i][30],
          STNDRD_KBN : msts[i][31],
          CTGRY_KBN : msts[i][32],
          RGSTRTN_USR_CD : usr_cd,
          UPDT_USR_CD : usr_cd,
        }
        mstdata.push(prdctMsts);
      }
      break;
      case "usrMst":
        msts.shift();
        for(var i = 0;i < msts.length; i++){// eslint-disable-line
          var usrMsts = {
            MST_NM: "usr_mst",
            CRUD : msts[i][0],
            USR_CD : msts[i][1],
            USR_NM : msts[i][2],
            PSSWRD : msts[i][3],
            ML_ADDR : msts[i][4],
            MST_MNTNNC_FLG : msts[i][5],
            UPDT_USR_CD : usr_cd
          }
          mstdata.push(usrMsts);
        }
        break;  
        case "cstmrMpgMst":
          msts.shift();
          for(var i = 0;i < msts.length; i++){// eslint-disable-line
            var cstmrMpgMsts = {
              MST_NM: "cstmr_mpg_mst",
              CRUD : msts[i][0],
              CSTMR_MNGMNT_NUM : msts[i][1],
              CSTMR_NM : msts[i][2],
              CSTMR_CD : msts[i][3],
              CSTMR_PRDCT_NUM : msts[i][4],
              IDNTFCTON_CD : msts[i][5],
              DLVRY_SLP_NO : msts[i][6],
              ITM_NO : msts[i][7],
              ORDR_KBN : msts[i][8],
              DLVRY_TM : msts[i][9],
              SHPPNG_SCDLE_TRN : msts[i][10],
              SHPPNG_SCDLE_DT : msts[i][11],
              SHPPNG_WREHS : msts[i][12],
              SHPPNG_KBN : msts[i][13],
              CSTMR_ORDR_NO : msts[i][14],
              SHPPNG_EXPNS_KBN : msts[i][15],
              DT_ARVL_DAGNTN : msts[i][16],
              TM_ARVL_DAGNTN : msts[i][17],
              SHPPNG_CMPNY : msts[i][18],
              INVICE_CNTNTS_1 : msts[i][19],
              RQEST_NM : msts[i][20],
              VIA_NM : msts[i][21],
              DLVRY_DSTNTON_NM_1 : msts[i][22],
              DLVRY_DSTNTON_NM_2 : msts[i][23],
              PST_CD : msts[i][24],
              PREFECTURES : msts[i][25],
              DLVRY_ADDRSS_1 : msts[i][26],
              DLVRY_ADDRSS_2 : msts[i][27],
              SCEN : msts[i][28],
              PHNE_CD : msts[i][29],
              INVICE_FAX_RQEST : msts[i][30],
              INVICE_FAX_PRSNC : msts[i][31],
              MAP_PRSNC : msts[i][32],
              PRDCT_CD : msts[i][33],
              PRDCT_NM : msts[i][34],
              QTY : msts[i][35],
              UNIT_PRC : msts[i][36],
              REMARKS_1 : msts[i][37],
              REMARKS_2_1 : msts[i][38],
              REMARKS_2_2 : msts[i][39],
              REMARKS_2_3 : msts[i][40],
              REMARKS_2_4 : msts[i][41],
              REMARKS_2_5 : msts[i][42],
              REMARKS_2_6 : msts[i][43],
              REMARKS_2_7 : msts[i][44],
              REMARKS_2_8 : msts[i][45],
              REMARKS_2_9 : msts[i][46],
              REMARKS_2_10 : msts[i][47],
              UPDT_USR_CD : usr_cd
            }
            mstdata.push(cstmrMpgMsts);
          }
          break;
          case "dlvryLdtmMst":
            msts.shift();
            for(var i = 0;i < msts.length; i++){// eslint-disable-line
              var dlvryLdtmMsts = {
                MST_NM: "dlvry_ldtm_mst",
                CRUD : msts[i][0],
                ORDR_NUM : msts[i][1],
                PRFCTR_NM : msts[i][2],
                PRFCTRS_NM : msts[i][3],
                LD_TM : msts[i][4],
                UPDT_USR_CD : usr_cd
              }
              mstdata.push(dlvryLdtmMsts);
            }
            break;
          case "clsfctonMst":
            msts.shift();
            for(var i = 0;i < msts.length; i++){// eslint-disable-line
              var clsfctonMsts = {
                MST_NM: "clsfcton_mst",
                CRUD : msts[i][0],
                IDNTFCTON_CD : msts[i][1],
                CLSFCTON_NUM : msts[i][2],
                IDNTFCTON_NM : msts[i][3],
                CLSFCTON_NM : msts[i][4],
                UPDT_USR_CD : usr_cd
              }
              mstdata.push(clsfctonMsts);
            }
            break;
            default:
            return "ERROR"
      }
    const mstUpdates = await axios.post("https://nasta-csvmngmnt.com/updateMsts",{mstdata});// eslint-disable-line
    if(mstUpdates.data == "ERROR"){
      return false
    }else{
      return true
    }
  }catch{
    return false;
  }
}

function stringcut(str) {
  var string = '';
  string = str.substr(0,25);
  return string
}

function shppngDateMolding(date) {
  var moldate = '';
  if(date != null){
    moldate = date.replace(/\//g,"-");
  }
  if(moldate == ""){
    moldate = 0
  }
  return moldate
}

function dateMolding(date) {
  if(date == null){
    date == 0
  }
  var moldate = '';
  moldate = date.replace(/\//g,"-");
  return moldate
}

function remarkMolding(str) {
  var molstr = '';
  molstr = str.replace(/\n/g,"\r\n");
  return molstr
}

export default {
  updates,
  mstUpdates
}