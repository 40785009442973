import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '@/views/login.vue'
import orders from '@/views/orders.vue'
import detail from '@/views/detail.vue'
import Import from '@/views/Import.vue'
import pssChng from '@/views/pssChng.vue'
import mstMntnnc from '@/views/mstMntnnc.vue'
import logintr from "@/store/index.js";

Vue.use(VueRouter)
  const routes = [
  {
    path: '*',
    redirect: '/login'
  },
  {
    path: '/login', 
    name: 'login',
    component: login,
    beforeEnter: (to, from, next) => {
      if(from.path == '/orders/import' && to.path == '/login'){
        next()
      }else if(from.path == '/orders' && to.path == '/login'){
        next()
      }else if(from.path == '/orders/pssChng' && to.path == '/login'){
        next()
      }else if(from.path == '/orders/mstMntnnc' && to.path == '/login'){
        next()
      }else if(from.path == '/' && to.path == '/login'){
        next()
      }else if(from.path == "/orders/" + from.params.MNGMNT_NUM && to.path == "/login"){
        next()
      }
    }
  },
  {
    path: '/orders',
    name: 'orders',
    component: orders,
    beforeEnter: (to, from, next) => {
      if(from.path == '/login' && to.path == '/orders') {
        if(logintr.state.login.isLogin){
          next()
        }else{
          next({path: '/login'})  
        }
      }else if(from.path == '/' && to.path == '/orders'){
        if(logintr.state.login.isLogin){
          next()
        }else{
          next({path: '/login'})  
        }
      }else if(from.path == '/orders/import' && to.path == '/orders'){
        next()
      }else if(from.path == '/orders/pssChng' && to.path == '/orders'){
        next()
      }else if(from.path == '/orders/mstMntnnc' && to.path == '/orders'){
        next()
      }else if(from.path == "/orders/" + from.params.MNGMNT_NUM && to.path == '/orders'){
        next()
      }
    }
  },
  {
    path: '/orders/pssChng',
    name: 'pssChng',
    component: pssChng,
    beforeEnter: (to, from, next) => {
      if(from.path == '/orders' && to.path == '/orders/pssChng'){
          next()
      }else if(from.path == '/' && to.path == '/orders/pssChng'){
        next()
      }
    }    
  },
  {
    path: '/orders/mstMntnnc',
    name: 'mstMntnnc',
    component: mstMntnnc,
    beforeEnter: (to, from, next) => {
      if(from.path == '/orders' && to.path == '/orders/mstMntnnc'){
        if(logintr.state.login.mstmnt == 1){
          next()
        }else{
          next({path: '/login'})
        }
      }else if(from.path == '/' && to.path == '/orders/mstMntnnc'){
        if(logintr.state.login.mstmnt == 1){
          next()
        }else{
          next({path: '/login'})
        }
      }
    }
  },
  {
    path: '/orders/import',
    name: 'Import',
    component: Import,
    beforeEnter: (to, from, next) => {
      if(from.path == '/orders' && to.path == '/orders/import'){
        next()
      }else if(from.path == '/' && to.path == '/orders/import'){
        next()
      }
    }
  },
  {
    path: '/orders/:MNGMNT_NUM',
    component: detail,
    props: true,
    beforeEnter: (to, from, next) => {
      if(from.path == '/' && to.path == "/orders/" + to.params.MNGMNT_NUM){
        next({path: '/orders'})
      }else if(from.path == '/orders/' + to.params.MNGMNT_NUM && to.path == "/orders/" + to.params.MNGMNT_NUM){
        next()
      }else if(from.path == '/orders' && to.path == "/orders/" + to.params.MNGMNT_NUM){
        next()
      }
    }    
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
