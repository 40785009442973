<template>
  <v-container style=" margin-top: -50px;">
    <v-row>
      <v-col cols="12">
        <v-card height="auto"  elevation="3" class="text-center">
          <v-form class="narrow_down">
            <div class="inline_1 clearfix">
              <v-menu :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="stRegisterFormatted" clearable label="登録日 - 開始" class="styled-input small" prepend-icon="mdi-calendar-multiple" maxlength="10" v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker locale="ja" v-model="stRgstVal"></v-date-picker>
              </v-menu>
              <span id="spanid">～</span>
              <v-menu :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="edRegisterFormatted" clearable label="登録日 - 終了" class="styled-input small" prepend-icon="mdi-calendar-multiple" maxlength="10" v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker locale="ja" v-model="edRgstVal"></v-date-picker>
              </v-menu>
              <v-text-field v-model="viaNmVal" clearable class="styled-input medium" label="経由先名" type="text"></v-text-field>
              <v-text-field v-model="scnVal" clearable class="styled-input medium" label="物件名" type="text"></v-text-field>
              <v-select v-model="csvVal" label="CSV" class="styled-input small" type="text" item-text="CLSFCTON_NM" item-value="CLSFCTON_NUM" :items="Csvkb"></v-select>	
            </div>
            <div class="inline_2 clearfix">
              <v-menu :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="stShppngScdleDateFormatted" clearable label="出荷予定日 - 開始" class="styled-input small" prepend-icon="mdi-calendar-multiple" maxlength="10" v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker locale="ja" v-model="stShppngScdlDateVal"></v-date-picker>
              </v-menu>
              <span id="spanid">～</span>
              <v-menu :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="edShppngScdleDateFormatted" clearable label="出荷予定日 - 終了" class="styled-input small" prepend-icon="mdi-calendar-multiple" maxlength="10" v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker locale="ja" v-model="edShppngScdlDateVal"></v-date-picker>
              </v-menu>
              <v-text-field v-model="cstmrOrdrNumVal" clearable class="styled-input medium" label="客先注文No" type="text"></v-text-field>
              <v-text-field v-model="dlvryDstntonNmVal" clearable class="styled-input medium" label="納品先名" type="text"></v-text-field>
              <v-select v-model="pdfVal" label="印刷" class="styled-input small" type="text" item-text="CLSFCTON_NM" item-value="CLSFCTON_NUM" :items="Ptkb"></v-select>
            </div>
            <div class="inline_3 clearfix">
              <v-menu :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="stDeliveryDateFormatted" clearable label="納品日 - 開始" class="styled-input small" prepend-icon="mdi-calendar-multiple" maxlength="10" v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker locale="ja" v-model="stDlvryDateVal"></v-date-picker>
              </v-menu>
              <span id="spanid">～</span>
              <v-menu :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field  v-model="edDeliveryDateFormatted" clearable label="納品日 - 終了" class="styled-input small" prepend-icon="mdi-calendar-multiple" maxlength="10" v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker locale="ja" v-model="edDlvryDateVal"></v-date-picker>
              </v-menu>
              <v-text-field v-model="prdctVal" clearable class="styled-input medium" label="製品コード" type="text"></v-text-field>
              <v-text-field v-model="dlvryAddrssVal" clearable class="styled-input medium" label="納品先住所" type="text"></v-text-field>
              <v-text-field v-model="orderNumVal" clearable class="styled-input small" label="受注番号" type="text"></v-text-field>
            </div>
            <div class="inline_4 clearfix">
              <div class="inlines">
                <div class="input_4_customer">
                  <v-select v-model="cstmrNmVal" label="得意先名" class="styled-input_4" type="text" item-text="CSTMR_NM" item-value="CSTMR_CD" :items="Cstmr"></v-select>
                </div>
                <div class="input_4">
                  <v-autocomplete label="登録ユーザー" item-text="USR_NM" item-value="USR_CD" :items="PREF" hide-no-data v-model="rgstrVal" class="styled-input_4"></v-autocomplete>
                </div>
                <div class="input_4">
                  <v-select v-model="sttsVal" label="ステータス" class="styled-input_4" type="text" item-text="CLSFCTON_NM" item-value="CLSFCTON_NUM" :items="Stskb"></v-select>
                </div>
              </div>
              <div class="btns">
                <v-btn class="sub" color="primary" elevation="9" @click="nallow">絞込</v-btn>
                <v-btn class="clrbtn" color="#FDBF2F" elevation="9" @click="clear">クリア</v-btn>
              </div>
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="justify-end">
      <v-btn class="white--text" color="#00ACF9" @click="imports">インポート</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import StorenallowList from "../store/modules/nallow.js";
import parseDate from "./javascript/parseDate.js";
import formatDate from "./javascript/formatDate.js";

export default {
  name: "nallows",
  data: () => ({
    stRgstVal: null,
    edRgstVal: null,
    viaNmVal: null,
    scnVal: null,
    csvVal: null,
    stShppngScdlDateVal: null,
    edShppngScdlDateVal: null,
    cstmrOrdrNumVal: null,
    dlvryDstntonNmVal: null,
    pdfVal: null,
    stDlvryDateVal: null,
    edDlvryDateVal: null,
    prdctVal: null,
    dlvryAddrssVal: null,
    orderNumVal: null,
    cstmrNmVal:null,
    rgstrVal: null,
    sttsVal: null,
  }),
  computed: {
    stRegisterFormatted: {
      get(){return formatDate.formatDate(this.stRgstVal)},
      set(x){this.stRgstVal = parseDate.parseDate(x)},
    },
    edRegisterFormatted: {
      get(){return formatDate.formatDate(this.edRgstVal)},
      set(x){this.edRgstVal = parseDate.parseDate(x)},
    },
    stShppngScdleDateFormatted: {
      get(){return formatDate.formatDate(this.stShppngScdlDateVal)},
      set(x){this.stShppngScdlDateVal = parseDate.parseDate(x)},
    },
    edShppngScdleDateFormatted: {
      get(){return formatDate.formatDate(this.edShppngScdlDateVal)},
      set(x){this.edShppngScdlDateVal = parseDate.parseDate(x)},
    },
    stDeliveryDateFormatted: {
      get(){return formatDate.formatDate(this.stDlvryDateVal)},
      set(x){this.stDlvryDateVal = parseDate.parseDate(x)},
    },
    edDeliveryDateFormatted: {
      get(){return formatDate.formatDate(this.edDlvryDateVal)},
      set(x){this.edDlvryDateVal = parseDate.parseDate(x)},
    },
    dates() {
      return this.st_value.join(' ~ ')
    },
    Csvkb(){
      return StorenallowList.state.csvkbs;
    },
    Ptkb(){
      return StorenallowList.state.ptkbs;
    },
    Rgkb(){
      return StorenallowList.state.rgkbs;
    },
    Stskb(){
      return StorenallowList.state.stskbs;
    },
    Cstmr(){
      return StorenallowList.state.cstmrs;
    },
    PREF(){
      return StorenallowList.state.users;
    },
  },
  methods: {
    async nallow() {
        if(this.stRgstVal == undefined){
          this.stRgstVal = null;
        }
        if(this.edRgstVal == undefined){
          this.edRgstVal = null;
        }
        if(this.stShppngScdlDateVal == undefined){
          this.stShppngScdlDateVal = null;
        }
        if(this.edShppngScdlDateVal == undefined){
          this.edShppngScdlDateVal = null;
        }
        if(this.stDlvryDateVal == undefined){
          this.stDlvryDateVal = null;
        }
        if(this.edDlvryDateVal == undefined){
          this.edDlvryDateVal = null;
        }
      this.$store.dispatch("nallow/nallow", {
        ST_RGSTRTN_DT: this.stRgstVal,
        ED_RGSTRTN_DT: this.edRgstVal,
        VIA_NM: this.viaNmVal,
        SCEN: this.scnVal,
        CSV_OUTPT_FLG: this.csvVal,
        ST_SHPPNG_SCDLE_DT: this.stShppngScdlDateVal,
        ED_SHPPNG_SCDLE_DT: this.edShppngScdlDateVal,
        CSTMR_ORDR_NO: this.cstmrOrdrNumVal,
        DLVRY_DSTNTON_NM: this.dlvryDstntonNmVal,
        DWNLD_FLG: this.pdfVal,
        ST_DLVRY_DT: this.stDlvryDateVal,
        ED_DLVRY_DT: this.edDlvryDateVal,
        PRDCT_CD: this.prdctVal,
        DLVRY_ADDRSS: this.dlvryAddrssVal,
        ORDR_NUM: this.orderNumVal,
        CSTMR_CD: this.cstmrNmVal,
        RGSTRTN_USR_CD: this.rgstrVal,
        STTS_KBN: this.sttsVal,
      });
    },
    clear() {
      this.stRgstVal = null;
      this.edRgstVal = null;
      this.stShppngScdlDateVal = null;
      this.edShppngScdlDateVal = null;
      this.stDlvryDateVal = null;
      this.edDlvryDateVal = null;
      this.prdctVal = null;
      this.viaNmVal = null;
      this.dlvryDstntonNmVal = null;
      this.cntrl_num_value = null;
      this.cstmrOrdrNumVal = null;
      this.dlvry_tm_value = null;
      this.dlvry_adrs_value = null;
      this.rmrks_value = null;
      this.scnVal = null;
      this.csvVal = null;
      this.pdfVal = null;
      this.cstmrNmVal = null;
      this.sttsVal = null;
      this.dlvryAddrssVal = null;
      this.orderNumVal = null;
    },
    imports() {
      this.$router.push("/orders/import");
    },
  },
  async mounted() {
    this.$store.dispatch("nallow/clsfcton");
    this.$store.dispatch("nallow/usermst");
    if(this.$store.state.nallow.RGSTRTN_USR_CD != null){
      this.rgstrVal = this.$store.state.nallow.RGSTRTN_USR_CD;
    }else{
      this.rgstrVal = this.$store.state.login.userId;
    }  
    this.stRgstVal = this.$store.state.nallow.ST_RGSTRTN_DT;
    this.edRgstVal = this.$store.state.nallow.ED_RGSTRTN_DT;
    this.viaNmVal = this.$store.state.nallow.VIA_NM;
    this.scnVal = this.$store.state.nallow.SCEN;
    this.csvVal = this.$store.state.nallow.CSV_OUTPT_FLG;
    this.stShppngScdlDateVal = this.$store.state.nallow.ST_SHPPNG_SCDLE_DT;
    this.edShppngScdlDateVal = this.$store.state.nallow.ED_SHPPNG_SCDLE_DT;
    this.cstmrOrdrNumVal = this.$store.state.nallow.CSTMR_ORDR_NO;
    this.dlvryDstntonNmVal = this.$store.state.nallow.DLVRY_DSTNTON_NM;
    this.pdfVal = this.$store.state.nallow.DWNLD_FLG;
    this.stDlvryDateVal = this.$store.state.nallow.ST_DLVRY_DT;
    this.edDlvryDateVal = this.$store.state.nallow.ED_DLVRY_DT;
    this.prdctVal = this.$store.state.nallow.PRDCT_CD;
    this.dlvryAddrssVal = this.$store.state.nallow.DLVRY_ADDRSS;
    this.orderNumVal = this.$store.state.nallow.ORDR_NUM;
    this.cstmrNmVal = this.$store.state.nallow.CSTMR_CD;
    this.sttsVal = this.$store.state.nallow.STTS_KBN;    
    if(this.stRgstVal == undefined){
      this.stRgstVal = null;
    }
    if(this.edRgstVal == undefined){
      this.edRgstVal = null;
    }
    if(this.stShppngScdlDateVal == undefined){
      this.stShppngScdlDateVal = null;
    }
    if(this.edShppngScdlDateVal == undefined){
      this.edShppngScdlDateVal = null;
    }
    if(this.stDlvryDateVal == undefined){
      this.stDlvryDateVal = null;
    }
    if(this.edDlvryDateVal == undefined){
      this.edDlvryDateVal = null;
    }
    this.$store.dispatch("nallow/nallow", {
      ST_RGSTRTN_DT: this.stRgstVal,
      ED_RGSTRTN_DT: this.edRgstVal,
      VIA_NM: this.viaNmVal,
      SCEN: this.scnVal,
      CSV_OUTPT_FLG: this.csvVal,
      ST_SHPPNG_SCDLE_DT: this.stShppngScdlDateVal,
      ED_SHPPNG_SCDLE_DT: this.edShppngScdlDateVal,
      CSTMR_ORDR_NO: this.cstmrOrdrNumVal,
      DLVRY_DSTNTON_NM: this.dlvryDstntonNmVal,
      DWNLD_FLG: this.pdfVal,
      ST_DLVRY_DT: this.stDlvryDateVal,
      ED_DLVRY_DT: this.edDlvryDateVal,
      PRDCT_CD: this.prdctVal,
      DLVRY_ADDRSS: this.dlvryAddrssVal,
      ORDR_NUM: this.orderNumVal,
      CSTMR_CD: this.cstmrNmVal,
      RGSTRTN_USR_CD: this.rgstrVal,
      STTS_KBN: this.sttsVal,
    });
  },
};
</script>

<style scoped>
.styled-input {
  width: 350px;
  float: left;
  text-align: center;
  height: 80px;
  margin: 0 10px;
}
.small {
  width: 100px;
}
.medium {
  width: 200px;
}
.input_4 {
  margin: 0 10px 0 10px;
  width: 300px;
}
.styled-input_4{
  float: left;
  text-align: center;
  height: 60px;
  margin: 0 10px;
  width: 100%;
}
.input_4_customer{
  width: 500px;
  margin: 0 10px 0 20px;
}
.narrow_down{
  display: block;
  padding: 10px;
  width: 100%;
  height: 100%;
   margin: auto auto;
  text-align: center;
}

.clearfix:after {
  content:" ";
  display:block;
  clear:both;
}
.inline_1{
  height: 65px;
  display: flex; 
}
.inline_2{
  height: 65px;
  display: flex; 
}
.inline_3{
  height: 65px;
  display: flex; 
}
.inline_4{
  height: 65px;
  display: flex;
}
.inlines{
  display: flex;
  float: left;

  width: 70%;
}
.btns{
  margin: auto 10px;
  width: 50%;
  float: right;
}
.sub{
  height: 60px;
  float: right;
  width: 100px;
}
.clrbtn{
  height: 60px;
  float: right;
  width: 100px;
  right: 30px;
  color: #ffffff;
}
#spanid {
  padding-top: 20px;
  width: 15px;
  height:20px;
  float: left;
}
.white--text {
  height: 60px;
  width: 100px;
  margin: 25px 30px 0 0;
}
.custom-color {
  background-color: aquamarine;
  border-color: aqua;
}
</style>

